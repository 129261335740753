import { ActionTree } from "vuex";
import { RootState } from "@/store/state";
import { WindowMutations } from "./mutations";
import { WindowInterface } from "./state";

import { useToast } from "vue-toastification";
const toast = useToast();
const toastOptions = {
	timeout: 1500,
	hideProgressBar: true,
};

import i18n from "@/resource/index";
const { t } = i18n.global;

export const actions: ActionTree<WindowInterface, RootState> = {
	openNewWindow(context, payload) {
		const windowRef = window.open();
		// const windowRef = window.open("", payload.windowName || "app_window");

		// check if new window is blocked
		if (!windowRef || windowRef?.closed) {
			toast.error(t("toast.popUpBlocked"), toastOptions);
			return;
		}

		// display loading animation
		if (payload.loading) {
			windowRef.document.write(`
			<html>
			<head>
			  <title>${t("common.onLoading")}</title>
			  <style>
				body {
				  display: flex;
				  justify-content: center;
				  align-items: center;
				  gap: 1rem;
				  height: 100vh;
				  font-family: Arial, sans-serif;
				}
				.spinner {
				  border: 4px solid rgba(0, 0, 0, 0.1);
				  width: 36px;
				  height: 36px;
				  border-radius: 50%;
				  border-top-color: #007bff;
				  animation: spin 1s ease-in-out infinite;
				}
				@keyframes spin {
				  to {
					transform: rotate(360deg);
				  }
				}
			  </style>
			</head>
			<body>
			  <div class="spinner"></div>
			  <p>${t("common.onLoading")}</p>
			</body>
			</html>
		  `);
		}

		context.commit(WindowMutations.PUSH_NEW_WINDOW, windowRef);
	},

	setNewWindow(context, payload) {
		const windowRef = context.state.windowList.slice(-1)?.[0];

		// set new window url
		if (windowRef && !windowRef?.closed) windowRef.location.href = payload.url;
	},

	closeNewWindow(context, payload) {
		const windowRef = context.state.windowList.slice(-1)?.[0];

		// close existing new window
		if (windowRef && !windowRef?.closed) windowRef?.close();
	},
};
