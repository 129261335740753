import httpClient, { ClientId } from "./HttpClient";
import { BonusResponse } from "@/types/Bonus/Response/BonusResponse";
import { BonusClaimedResponse } from "@/types/Bonus/Response/BonusClaimedResponse";
import { UniqueCodeResponse } from "@/types/Bonus/Response/UniqueCodeResponse";
import { ActiveBonusAppliedResponse } from "@/types/Bonus/Response/ActiveBonusAppliedResponse";
import {
	ActiveBonusAppliedWithBonusVendorResponse,
	MemberForfeitedBonusByVendorResponse,
} from "@/types/Bonus/Response/ActiveBonusAppliedWithBonusVendorResponse";
import { MemberGroupBonusResponse } from "@/types/Bonus/Response/MemberGroupBonusResponse";
import { ClaimBonusRequest, ClaimBonusIdRequest, DirectClaimRequest } from "@/types/Bonus/Request/ClaimBonusRequest";
import {
	ClaimBonusResponse,
	ClaimBonusIdResponse,
	DirectClaimResponse,
} from "@/types/Bonus/Response/ClaimBonusResponse";
import { ClaimMemberReferralResponse } from "@/types/Bonus/Response/ClaimMemberReferralResponse";
import { ClaimMemberReferralRequest } from "@/types/Bonus/Request/ClaimMemberReferralRequest";
import { encrypt } from "@/composables/useEncryption";

const getBonusList = (): Promise<BonusResponse[]> => {
	const endPoint = `/api/Bonus/${ClientId}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getBonusByMemberGroup = (memberId: string): Promise<BonusResponse[]> => {
	const tenantId = process.env.VUE_APP_IdentityServer_TenantId;
	const endPoint = `/api/Bonus/${tenantId}/${ClientId}/MemberGroup/${memberId}`;
	return httpClient.get(endPoint).then((response) => response.data);
};
const getMemberPreBonus = (
	memberId: string,
	vendorToId: number,
	langId: number,
	bonusId?: number
): Promise<MemberGroupBonusResponse[]> => {
	const endPoint = `/api/Bonus/PreBonus`;
	const params = {
		memberId: memberId,
		languageId: langId,
		vendorToId: vendorToId,
		bonusId: bonusId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getUniqueCode = (): Promise<UniqueCodeResponse[]> => {
	const endPoint = `/api/Bonus/UniqueCode/${ClientId}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getUniqueCodeClaimedStatus = (loginId: string, uniqueCodeId: number): Promise<boolean> => {
	const endPoint = `/api/Bonus/UniqueCode/${ClientId}/loginId/${loginId}/${uniqueCodeId}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getBonusClaimed = (
	memberId: string,
	from: string,
	to: string,
	lang?: number
): Promise<BonusClaimedResponse[]> => {
	const endPoint = `/api/Bonus/Claims/${ClientId}/${memberId}`;
	const params = {
		clientId: ClientId,
		memberGUID: memberId,
		from: from,
		to: to,
		lang: lang,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getActiveBonusApplied = (memberId: string): Promise<ActiveBonusAppliedResponse[]> => {
	const endPoint = `/api/Bonus/ActiveBonusApplied`;
	const params = {
		memberGUID: memberId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const claimBonus = (memberId: string, bonusID: number): Promise<ClaimBonusResponse> => {
	const endPoint = `/api/Bonus/Claim/${ClientId}`;
	const request: ClaimBonusRequest = {
		memberGUID: memberId,
		amount: 0,
		bonusID: bonusID,
		id: bonusID,
		clientId: ClientId,
		isDirectBonus: false,
	};
	return httpClient.post(endPoint, request).then((response) => response.data);
};

const claimBonusId = (
	memberId: string,
	bonusClaimId: number,
	loginId: string,
	bonusId: number
): Promise<ClaimBonusIdResponse> => {
	const endPoint = `/api/Bonus/Claim/${ClientId}/${bonusClaimId}`;
	const request: ClaimBonusIdRequest = {
		id: bonusClaimId,
		memberGUID: memberId,
		loginId: loginId,
		bonusID: bonusId,
		clientId: ClientId,
		status: 1,
	};
	return httpClient.put(endPoint, request).then((response) => response.data);
};

const directClaim = (memberId: string, loginId: string, bonusId: number): Promise<DirectClaimResponse> => {
	const endPoint = `/api/Bonus/DirectClaim`;
	const request: DirectClaimRequest = {
		hash: encrypt({
			memberGUID: memberId,
			loginId: loginId,
			bonusId: bonusId,
			clientId: ClientId,
		}),
	};
	return httpClient.post(endPoint, request).then((response) => response.data);
};

const claimMemberReferralBonus = (
	memberId: string,
	bonusId: number | undefined
): Promise<ClaimMemberReferralResponse> => {
	const endPoint = `/api/MemberReferralBonus/ClaimMemberReferralBonus`;
	const request: ClaimMemberReferralRequest = {
		referralMemberGUID: memberId,
		referralBonusId: bonusId,
	};
	return httpClient.post(endPoint, request).then((response) => response.data);
};

const getActiveBonusAppliedWithBonus = (memberId: string): Promise<ActiveBonusAppliedWithBonusVendorResponse[]> => {
	const endPoint = `/api/Bonus/ActiveBonusAppliedWithBonusVendor`;
	const params = {
		memberGUID: memberId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getMemberForfeitedBonusByVendor = (
	memberId: string,
	vendorName: string
): Promise<MemberForfeitedBonusByVendorResponse[]> => {
	const endPoint = `/api/Bonus/getMemberForfeitedBonusByVendor`;
	const params = {
		memberGUID: memberId,
		clientId: ClientId,
		vendorName: vendorName,
		isFrontEnd: true,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export default {
	getBonusList,
	getBonusByMemberGroup,
	getMemberPreBonus,
	getUniqueCode,
	getUniqueCodeClaimedStatus,
	getBonusClaimed,
	getActiveBonusApplied,
	claimBonus,
	claimBonusId,
	directClaim,
	claimMemberReferralBonus,
	getActiveBonusAppliedWithBonus,
	getMemberForfeitedBonusByVendor,
};
