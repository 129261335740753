import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { MemberGroupStateInterface } from "./state";
import {
  MemberTierResponse,
  MemberGroupListResponse,
} from "@/types/MemberGroup/Response/MemberGroupResponse";

export const getters: GetterTree<MemberGroupStateInterface, RootState> = {
  getMemberCurrentTier(state): MemberTierResponse {
    return state.memberCurrentTier;
  },
  getMemberGroupList(state): MemberGroupListResponse {
    return state.memberGroupList;
  },
};
