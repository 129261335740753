import { ActionTree } from "vuex";
import { getMiniGamesList, getMiniGamesUrl, getMiniGames } from "@/network/MiniGamesService/MiniGames";
import { MiniGamesStateInterface } from "./state";
import { RootState } from "@/store/state";
import { MiniGamesMutations } from "./mutations";
import moment from "moment";
const timezone = new Date().getTimezoneOffset();

export const actions: ActionTree<MiniGamesStateInterface, RootState> = {
	async fetchMiniGamesUser(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await getMiniGames(loginId, loginId);
		context.commit(MiniGamesMutations.SET_MINIGAME_USER, res);
		return res;
	},
	async clearMiniGamesUser(context, payload) {
		context.commit(MiniGamesMutations.SET_MINIGAME_USER, {});
	},
	async fetchMiniGames(context) {
		const dateNow = moment.utc(new Date()).format();
		if (context.state.miniGamesList.length > 0) {
			return;
		}
		let miniGameList = await getMiniGamesList();
		miniGameList = miniGameList.filter(
			(x) =>
				x.status == true &&
				moment(dateNow).isAfter(moment(x.startDate)) &&
				(x.endDate == null || moment(dateNow).isBefore(moment(x.endDate)))
		);
		context.commit(MiniGamesMutations.SET_MINIGAMES_LIST, miniGameList);
	},
	async fetchMiniGamesUrl(context, payload) {
		const res = await getMiniGamesUrl(payload.lang, payload.minigameId);
		context.commit(MiniGamesMutations.SET_MINIGAME_URL, res);
		return res;
	},
};
