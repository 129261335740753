export enum GameType {
	SLOTS = "SL",
	LIVE_CASINO = "LC",
	"LIVE CASINO" = "LC",
	FISHING = "FS",
	SPORTS = "SP",
	COCKFIGHT = "CF",
	LOTTERY = "LT",
	FAST_GAME = "FG",
	"FAST GAME" = "FG",
	POKER = "PK",
	ESPORTS = "ES",
	EGAME = "EG",
	TABLE = "TB",
}

export const hotVendorList = ["KAYA918", "BTG"];
export const vipVendorList = ["BTGVIP"];
