import { MemberInboxResponse } from "@/types/Inbox/Response/MemberInboxResponse";
import { KeywordResponse } from "@/types/Inbox/Response/KeywordResponse";

export interface InboxStateInterface {
	memberInbox: MemberInboxResponse;
	keyword: KeywordResponse;
	memberInboxList: MemberInboxResponse[];
	inboxUnreadCount: number;
}

function state(): InboxStateInterface {
	return {
		memberInbox: {} as MemberInboxResponse,
		keyword: {} as KeywordResponse,
		memberInboxList: [] as MemberInboxResponse[],
		inboxUnreadCount: 0,
	};
}

export default state;
