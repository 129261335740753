import { livaDataStateInterface } from "./state";
import { MutationTree } from "vuex";
import { liveTransactionResponse } from "@/types/LiveData/Response/liveDataResponse";

export enum livaDataMutations {
	SET_PROGRESSIVE_JACKPOT_VALUE = "SET_PROGRESSIVE_JACKPOT_VALUE",
	SET_LIVE_DEPOSIT = "SET_LIVE_DEPOSIT",
	SET_LIVE_WITHDRAWAL = "SET_LIVE_WITHDRAWAL",
}

export const mutations: MutationTree<livaDataStateInterface> = {
	[livaDataMutations.SET_PROGRESSIVE_JACKPOT_VALUE](state, payload: number) {
		state.progressiveJackpotValue = payload;
	},
	[livaDataMutations.SET_LIVE_DEPOSIT](state, payload: liveTransactionResponse[]) {
		state.liveDepositList = payload;
	},
	[livaDataMutations.SET_LIVE_WITHDRAWAL](state, payload: liveTransactionResponse[]) {
		state.liveWithdrawalList = payload;
	},
};
