<template>
	<Transition name="fade-out">
		<div
			v-if="queue.total"
			class="loadingBar"
			:class="{ infinite: infinite }"
			:style="`width: ${infinite ? 100 : completePercent}%`"
		/>
		<!-- <div class="loadingBar" :style="`width: 100%`" /> -->
	</Transition>
</template>

<script setup>
import { computed, watch, defineProps } from "vue";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
	infinite: {
		type: Boolean,
		default: false,
	},
});

const queue = computed(() => store.getters["progressTracker/getTaskQueue"]);

// show at least 10%
const completePercent = computed(() => {
	return queue.value.total ? Math.max(10, (queue.value.completed / queue.value.total) * 100) : 0;
});

const clearQueue = () => {
	if (queue.value.completed >= queue.value.total) store.dispatch("progressTracker/clearQueue");
};

watch(queue, (newQueue) => {
	if (newQueue.completed >= newQueue.total && newQueue.total > 0) {
		setTimeout(clearQueue, 500);
	}
});
</script>

<style lang="sass" scoped>
.loadingBar
	position: absolute
	z-index: 31
	height: 3px
	width: 0
	background: var(--border-accent)
	transition: 0.2s
	&.infinite
		background: linear-gradient(to right, var(--border-accent) 50%, transparent 50%)
		background-size: 200%
		animation: infiniteLoading 2s ease-in-out infinite
	@media (max-width: 767px)
		background: var(--color-secondary)
		&.infinite
			background: linear-gradient(to right, var(--color-secondary) 50%, transparent 50%)

@keyframes infiniteLoading
	0%
		background-position: 100%
	100%
		background-position: -100%

.fade-out-leave-active
	transition: all 0.4s ease-in-out
.fade-out-leave-to
	opacity: 0
</style>
