<template>
	<div class="inputPasswordContainer">
		<label class="inputLabel"
			><span v-if="label" class="inputLabelText" :class="{ required: required }">{{ label }}</span>

			<div class="inputContainer">
				<input
					:type="showPassword ? 'text' : 'password'"
					inputmode="text"
					class="defaultInput floatRightIconInput"
					:value="modelValue"
					:placeholder="placeholder || defaultPlaceholder"
					:required="required"
					:disabled="disabled"
					@input="(event) => emit('update:modelValue', event.target.value)"
				/>

				<font-awesome-icon
					:icon="showPassword ? ['fas', 'eye'] : ['fas', 'eye-slash']"
					class="showHideIcon inputIcon"
					:class="{ disabled: disabled }"
					@click="toggleShowHide"
				/>
			</div>
		</label>

		<div class="inputError" v-if="errors.length > 0">
			{{ errors[0]?.$message }}
		</div>
	</div>
</template>

<script setup>
import { ref } from "vue";
import { defineProps, defineEmits } from "vue";
import { authField } from "@/composables/useAuth";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
	modelValue: {
		type: String,
		default: "",
	},
	label: {
		type: String,
		default: null,
	},
	required: {
		type: Boolean,
		default: false,
	},
	placeholder: {
		type: String,
		default: null,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	errors: {
		type: Array,
		default: () => [],
	},
});

const defaultPlaceholder = ref(
	t("placeholder.characters", {
		minLength: authField.MIN_PASSWORD_LENGTH,
		maxLength: authField.MAX_PASSWORD_LENGTH,
	})
);

const showPassword = ref(false);

const toggleShowHide = () => {
	showPassword.value = !showPassword.value;
};
</script>

<style lang="sass" scoped>
.showHideIcon
  width: 1.4rem
  font-size: 1.1em
</style>
