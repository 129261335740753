import { ActionTree } from "vuex";
import winLossesService from "@/network/WinLossesService/WinLosses";
import { WinLossesStateInterface } from "./state";
import { RootState } from "@/store/state";
import { WinLossesMutations } from "./mutations";
import { clientDateStringToServerISOString } from "@/composables/useCountry";

export const actions: ActionTree<WinLossesStateInterface, RootState> = {
	async fetchBetHistory(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await winLossesService.getBetHistory(
			memberId,
			clientDateStringToServerISOString(payload.from),
			clientDateStringToServerISOString(payload.to, true)
		);
		context.commit(WinLossesMutations.SET_BET_HISTORY, res);
	},
};
