import { ActionTree } from "vuex";
import {
	getTokenAsync,
	getRegisterTokenAsync,
	getLoginUserInfo,
	resumeSession,
} from "@/network/IdentityServerService/IdentityServer";
import memberService from "@/network/MemberService/Member";
import { IdentityServerStateInterface } from "./state";
import { RootState } from "@/store/state";
import { IdentityServerMutations } from "./mutations";
import { MemberAccountMutations } from "@/store/MemberAccountModule/mutations";
import { MiniGamesMutations } from "@/store/MiniGamesModule/mutations";
import { useLogout } from "@/composables/useAuth";
import CryptoJS from "crypto-js";
import UAParser from "ua-parser-js";

// let timer: ReturnType<typeof setTimeout>;

export const actions: ActionTree<IdentityServerStateInterface, RootState> = {
	async fetchLoginToken(context, payload) {
		// const ipAddress = await context.dispatch("ipAddress/fetchIPAddress", null, {
		//   root: true,
		// });
		const parser = new UAParser();
		const userAgent = parser.getResult();

		//This is the user agent details
		const getFormattedUserAgentInfo = (info) => {
			const parts = [];

			if (info.device.type === "mobile") {
				if (info.device.vendor) {
					parts.push(info.device.vendor);
				}
				if (info.device.model) {
					parts.push(info.device.model);
				}
			} else {
				parts.push("");
			}

			if (info.os.name) {
				parts.push(info.os.name);
			}
			if (info.os.version) {
				parts.push(info.os.version);
			}

			return parts.join("|");
		};

		const userAgentOs = userAgent.os.name; // This is user agent OS
		const userAgentDetail = getFormattedUserAgentInfo(userAgent);

		const ipAddress = context.rootGetters["ipAddress/getIPAddress"];

		const res = await getTokenAsync(payload.phoneNo, payload.password, ipAddress, userAgentOs, userAgentDetail);
		if (res.loginStatus) {
			const expirationDate = new Date().getTime() + res.expires_in * 1000;
			//Token Encryption
			const cipher = CryptoJS.AES.encrypt(
				res.access_token,
				CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Encryption_Key),
				{
					iv: CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Initial_Vector),
					mode: CryptoJS.mode.CBC,
				}
			);
			memberService.memberLoginAction(payload.phoneNo);

			//Insert value into local storage
			localStorage.setItem("token", cipher.toString());
			localStorage.setItem("sid", res.sid);

			//Fetch User Info
			await context
				.dispatch("fetchUserInfo", res.access_token)
				.then(() => {
					localStorage.setItem("tokenExpiration", expirationDate.toString());
				})
				.catch(() => {
					localStorage.removeItem("token");
					localStorage.removeItem("sid");
					throw new Error();
				});
			context.commit(IdentityServerMutations.SET_LOGIN_STATUS, {
				loginStatus: true,
			});
			context.commit(IdentityServerMutations.SET_ONSUBMIT_LOGIN, {
				submitLoginStatus: true,
			});
			context.commit(IdentityServerMutations.SET_SID, res.sid);
			return { loginStatus: true };
		} else {
			return res;
		}
	},

	async fetchRegisterToken() {
		return await getRegisterTokenAsync().then((response) => response.access_token);
	},

	async fetchUserInfo(context) {
		const res = await getLoginUserInfo();
		context.commit(IdentityServerMutations.SET_MEMBER_INFO, res);
		return res;
	},

	async validation(_, payload) {
		const res = await resumeSession(payload.sub);
		return res;
	},

	async tryLogin(context) {
		const token = localStorage.getItem("token");
		const tokenExpiration = localStorage.getItem("tokenExpiration");
		const sid = localStorage.getItem("sid");

		if (sid) {
			context.commit(IdentityServerMutations.SET_SID, sid);
		}

		if (token) {
			const isInterchange = context.rootGetters["identityServer/getInterchangeStatus"];
			if (!isInterchange) {
				await context
					.dispatch("fetchUserInfo")
					.then(() => {
						if (tokenExpiration != null) {
							const expiresIn = +tokenExpiration - new Date().getTime();
							if (expiresIn > 0) {
								if (expiresIn < 3600 * 1000) {
									context.dispatch("logOut");
									return;
								}
							}
							setTimeout(function () {
								useLogout(true, true, false, true);
							}, expiresIn);
						}
						context.commit(IdentityServerMutations.SET_LOGIN_STATUS, {
							loginStatus: true,
						});
						context.dispatch("setInterchangeStatus", false);
					})
					.catch(() => {
						localStorage.removeItem("token");
						localStorage.removeItem("tokenExpiration");
					});
				context.commit(IdentityServerMutations.SET_ONREFRESH_STATUS, {
					refreshStatus: false,
				});
			}
		} else {
			context.commit(IdentityServerMutations.SET_ONREFRESH_STATUS, {
				refreshStatus: false,
			});
			return;
		}
	},

	logOut(context) {
		localStorage.removeItem("token");
		localStorage.removeItem("sid");
		context.commit(IdentityServerMutations.SET_MEMBER_INFO, {
			name: "",
			sub: "",
		});
		context.commit(IdentityServerMutations.SET_LOGIN_STATUS, {
			loginStatus: false,
		});
		context.commit(IdentityServerMutations.SET_SID, null);
		// context.commit(MemberAccountMutations.SET_MEMBER_WALLET, {
		// 	balance: 0,
		// 	lockedBalance: 0,
		// });

		// Move these to useLogout()
		// context.dispatch("miniGames/clearMiniGamesUser", {}, { root: true });
		// context.dispatch("inbox/clearMemberInbox", 0, { root: true });
		// context.dispatch("memberGroup/clearMemberCurrentTier", {}, { root: true });
	},

	async setInterchangeStatus(context, payload) {
		context.commit(IdentityServerMutations.SET_INTERCHANGE, payload);
	},
	async tokenTransfer(context, payload) {
		const cipher = CryptoJS.AES.encrypt(
			payload.token,
			CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Encryption_Key),
			{
				iv: CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Initial_Vector),
				mode: CryptoJS.mode.CBC,
			}
		);

		//Insert value into local storage
		localStorage.setItem("token", cipher.toString());
	},
	async setDisconnectSignalr(context, payload) {
		context.commit(IdentityServerMutations.SET_DISCONNECT_SIGNALR, payload);
	},
};
