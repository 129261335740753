import { ActionTree } from "vuex";
import bonusService from "@/network/BonusService/Bonus";
import { BonusStateInterface } from "./state";
import { RootState } from "@/store/state";
import { BonusMutations } from "./mutations";
import { clientDateStringToServerISOString } from "@/composables/useCountry";
import { isFeatureEnabled } from "@/constant";

export const actions: ActionTree<BonusStateInterface, RootState> = {
	async fetchBonusList(context) {
		if (context.state.bonusList.length > 0) {
			return;
		}
		let res = await bonusService.getBonusList();
		if (!isFeatureEnabled["REGISTER"]) res = res.filter((bonus) => bonus.bonusGroup.group != "NEW MEMBER");
		if (!isFeatureEnabled["REFERRAL_CLAIM"]) res = res.filter((bonus) => bonus.bonusGroup.group != "REFERRAL");
		context.commit(BonusMutations.SET_BONUS_ALL_LIST, res);
		context.commit(BonusMutations.SET_BONUS_LIST, res);
	},
	async fetchMemberBonusList(context) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await bonusService.getBonusByMemberGroup(memberId);
		context.commit(BonusMutations.SET_MEMBER_BONUS, res);
	},
	async fetchMemberPreBonusList(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		let res = await bonusService.getMemberPreBonus(memberId, payload.vendorToId, payload.langId, payload.bonusId);
		if (!isFeatureEnabled["REGISTER"]) res = res.filter((bonus) => bonus.group != "NEW MEMBER");
		if (!isFeatureEnabled["REFERRAL_CLAIM"]) res = res.filter((bonus) => bonus.group != "REFERRAL");
		if (payload.keepStore) {
			context.commit(BonusMutations.SET_MEMBER_PRE_BONUS, res);
		}
		return res;
	},
	async clearMemberPreBonusList(context, payload) {
		context.commit(BonusMutations.SET_MEMBER_PRE_BONUS, []);
	},
	async fetchUniqueCode(context) {
		const res = await bonusService.getUniqueCode();
		context.commit(BonusMutations.SET_UNIQUE_CODE, res);
	},
	async fetchUniqueCodeStatus(context, payload) {
		const res = await bonusService.getUniqueCodeClaimedStatus(payload.loginId, payload.uniqueCodeId);
		return res;
	},
	async fetchBonusClaim(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await bonusService.getBonusClaimed(
			memberId,
			clientDateStringToServerISOString(payload.from),
			clientDateStringToServerISOString(payload.to, true),
			payload.lang
		);
		context.commit(BonusMutations.SET_BONUS_CLAIMED, res);
	},
	async fetchActiveBonusApplied(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await bonusService.getActiveBonusApplied(memberId);
		const bonusList = context.rootGetters["bonus/getBonusList"];

		context.commit(BonusMutations.SET_ACTIVE_BONUS_APPLIED, res);
	},
	async claimBonus(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		return await bonusService.claimBonus(memberId, payload.bonusID);
	},
	async claimBonusId(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const loginId = context.rootGetters["identityServer/getLoginId"];
		return await bonusService.claimBonusId(memberId, payload.bonusClaimId, loginId, payload.bonusId);
	},
	async directClaim(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const loginId = context.rootGetters["identityServer/getLoginId"];
		return await bonusService.directClaim(memberId, loginId, payload.bonusId);
	},
	async claimMemberReferralBonus(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		return await bonusService.claimMemberReferralBonus(memberId, payload.referralBonusId);
	},
	async fetchActiveBonusAppliedWithBonusVendor(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await bonusService.getActiveBonusAppliedWithBonus(memberId);

		context.commit(BonusMutations.SET_ACTIVE_BONUS_WITH_VENDOR_APPLIED, res);
	},
	async fetchMemberForfeitedBonusByVendor(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		let res = [];
		if (payload?.vendorName) res = await bonusService.getMemberForfeitedBonusByVendor(memberId, payload.vendorName);
		context.commit(BonusMutations.SET_MEMBER_FORFEITED_BY_BONUS_VENDOR, res);
		return res;
	},
};
