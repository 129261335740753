<template>
	<div class="progressStepContainer">
		<div class="progressStepList" ref="list">
			<div
				v-for="progress in progressListSorted"
				:key="progress.step"
				class="progressStep"
				:class="{
					active: progress.step <= currentProgress,
					hasAction: progress.action,
					complete: currentProgress > progress.step,
				}"
				@click="progress.action && currentProgress == progress.step ? handleClick(progress.step) : null"
			>
				<div class="progressIcon">
					<span v-if="showStepNumber">{{ progress.step }}</span>
					<font-awesome-icon v-else :icon="['fas', 'check']" />
				</div>

				<div class="progressName">
					{{ currentProgress > progress.step && progress.action ? progress.completeName : progress.name }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from "vue";
import { useElementSize } from "@vueuse/core";

const emit = defineEmits(["clickProgress"]);

const props = defineProps({
	progressList: {
		type: Array,
		default: () => [],
		// { step: number, name: string, action: boolean, completeName: string}[]
	},
	currentProgress: {
		type: Number,
		default: 1,
	},
	showStepNumber: {
		type: Boolean,
		default: false,
	},
});

const progressListSorted = computed(() => {
	return [...props.progressList].sort((a, b) => a.step - b.step);
});

const list = ref(null);
const listWidth = useElementSize(list).width;
const connectorLength = computed(
	() => (listWidth.value - 50 * progressListSorted.value.length) / (progressListSorted.value.length - 1) + "px"
);

const handleClick = () => {
	emit("clickProgress");
};
</script>

<style lang="sass" scoped>
.progressStepList
  display: flex
  justify-content: space-between

.progressStep
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  gap: 6px
  width: 50px
  &:not(:last-child)::after
    content: ''
    position: absolute
    top: 12px
    right: 0
    transform: translate(100%, -50%)
    width: v-bind('connectorLength')
    height: 2px
    background: var(--border-regular)
  &.active
    .progressIcon
      background: var(--success)
    &::after
      background: var(--success)
    &.hasAction
      &:not(.complete)
        cursor: pointer
        filter: brightness(1.15)
        .progressIcon
          background: transparent
          outline: 2px solid var(--success)
          color: var(--success)
          box-shadow: 0 0 12px 0 var(--success)
        .progressName
          font-weight: var(--font-bold)
          color: var(--success)


.progressIcon
  display: flex
  justify-content: center
  align-items: center
  width: 24px
  height: 24px
  font-weight: var(--font-medium)
  background: var(--border-regular)
  color: var(--text-inverted)
  border-radius: 50%
  user-select: none
.progressName
  text-align: center
  font-size: 0.9em
  line-height: normal
</style>
