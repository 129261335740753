<template>
	<div class="inputSearchContainer">
		<label for="inputSearch" class="inputLabel"
			><span v-if="label" class="inputLabelText">{{ label }}</span>

			<div class="inputContainer">
				<input
					id="inputSearch"
					type="search"
					class="defaultInput floatLeftIconInput clearableInput"
					:value="modelValue"
					:placeholder="placeholder || t('common.search')"
					:disabled="disabled"
					@input="(event) => emit('update:modelValue', event.target.value)"
				/>

				<font-awesome-icon
					:icon="['fas', 'search']"
					class="searchIcon inputIcon"
					:class="{ disabled: disabled }"
				/>

				<font-awesome-icon
					v-if="modelValue != ''"
					:icon="['fas', 'circle-xmark']"
					class="clearInputIcon"
					@click="emit('update:modelValue', '')"
				/>
			</div>
		</label>
	</div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
	modelValue: {
		type: String,
		default: "",
	},
	label: {
		type: String,
		default: null,
	},
	placeholder: {
		type: String,
		default: null,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});
</script>

<style lang="sass" scoped></style>
