import { MiniGamesStateInterface } from "./state";
import { MiniGamesListResponse } from "@/types/MiniGames/Response/MiniGamesListResponse";
import { MiniGamesUserResponse } from "@/types/MiniGames/Response/MiniGamesUserResponse";
import { MutationTree } from "vuex";

export enum MiniGamesMutations {
	SET_MINIGAMES_LIST = "SET_MINIGAMES_LIST",
	SET_MINIGAME_URL = "SET_MINIGAME_URL",
	SET_MINIGAME_USER = "SET_MINIGAME_USER",
}

export const mutations: MutationTree<MiniGamesStateInterface> = {
	[MiniGamesMutations.SET_MINIGAMES_LIST](state, payload: MiniGamesListResponse[]) {
		state.miniGamesList = payload;
	},
	[MiniGamesMutations.SET_MINIGAME_URL](state, payload: string) {
		state.miniGameUrl = payload;
	},
	[MiniGamesMutations.SET_MINIGAME_USER](state, payload: MiniGamesUserResponse) {
		state.miniGameUser = payload;
	},
};
