import { MemberGroupStateInterface } from "./state";
import { MutationTree } from "vuex";
import {
  MemberTierResponse,
  MemberGroupListResponse,
} from "@/types/MemberGroup/Response/MemberGroupResponse";

export enum MemberGroupMutations {
  SET_MEMBER_CURRENT_TIER = "SET_MEMBER_CURRENT_TIER",
  SET_MEMBER_GROUP_LIST = "SET_MEMBER_GROUP_LIST",
}

export const mutations: MutationTree<MemberGroupStateInterface> = {
  [MemberGroupMutations.SET_MEMBER_CURRENT_TIER](
    state,
    payload: MemberTierResponse
  ) {
    state.memberCurrentTier = payload;
  },
  [MemberGroupMutations.SET_MEMBER_GROUP_LIST](
    state,
    payload: MemberGroupListResponse
  ) {
    state.memberGroupList = payload;
  },
};
