import { computed } from "vue";
import { useToast } from "vue-toastification";
import router from "@/router";
import i18n from "@/resource/index";
import store from "@/store/index";

const { t } = i18n.global;
const toast = useToast();

const authField = {
	MIN_PASSWORD_LENGTH: 6,
	MAX_PASSWORD_LENGTH: 16,
};

const isLogin = computed(() => {
	return store.getters["identityServer/getLoginStatus"];
});

// ================= Logout Start =================

const useLogout = async (showToast = true, refresh = false, passive = false, expired = false) => {
	await store.dispatch("identityServer/setDisconnectSignalr", true);
	await store.dispatch("identityServer/logOut");
	await store.dispatch("member/resetMember");
	await store.dispatch("member/resetMemberBank");
	await store.dispatch("memberAccount/clearMemberData");
	await store.dispatch("miniGames/clearMiniGamesUser");
	await store.dispatch("inbox/clearMemberInbox");
	await store.dispatch("memberGroup/clearMemberCurrentTier");

	router.push("/");

	if (showToast) {
		toast.success(t(expired ? "toast.sessionExpired" : "toast.logoutSuccess"), {
			timeout: expired ? 5000 : 1500,
			pauseOnFocusLoss: !refresh,
			pauseOnHover: !refresh,
			hideProgressBar: !expired,
			onClick: () => (refresh ? window.location.reload() : null),
			onClose: () => (refresh ? window.location.reload() : null),
		});
	}

	// passive: initiated by other tabs
	// notify other tabs
	if (!passive) emitLogout();
};

const emitLogout = () => localStorage.setItem("logout", Date.now().toString());
const watchLogout = () => {
	window.addEventListener("storage", (event) => {
		// passively logout if another tab initiated logout
		if (event.key === "logout") useLogout(true, true, true);
	});
};

// ================= Logout End =================

export { authField, isLogin, useLogout, emitLogout, watchLogout };
