import { createI18n } from "vue-i18n";
import zh from "./zh";
import en from "./en";
import ms from "./ms";

const initLocale = () => {
	let currentLocale = localStorage.getItem("locale") || navigator.language.slice(0, 2);
	if (!["en", "zh", "ms"].some((language) => language === currentLocale)) {
		currentLocale = "en";
		localStorage.setItem("locale", currentLocale);
	}
	document.querySelector("html").setAttribute("lang", currentLocale);
	return currentLocale;
};

const i18n = createI18n({
	locale: initLocale(),
	legacy: false,
	messages: {
		zh: zh,
		en: en,
		ms: ms,
	},
	warnHtmlMessage: false,
});

export default i18n;
