<template>
	<div class="privacyPolicyContainer container-sm">
		<div class="privacyPolicyDescription">
			{{ t("privacyPolicy.Description") }}
		</div>

		<div class="privacyPolicyContent">
			<div
				class="privacyPolicyItem cursorPointer"
				v-for="policy in policyList"
				:key="policy.id"
				@click="toggle(policy.id)"
			>
				<div class="privacyPolicyName" :class="policy.id == currentId ? 'open' : ''">
					{{ policy.id + ". " + policy.title }}

					<font-awesome-icon
						:icon="['fas', 'chevron-down']"
						class="arrow"
						:class="{ active: policy.id == currentId }"
					/>
				</div>

				<div class="privacyPolicyDetails" v-if="policy.id == currentId" v-html="policy.description"></div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

const { t, locale } = useI18n();
const router = useRouter();
const route = useRoute();

const policyList = computed(() => [
	{
		id: 1,
		title: t("privacyPolicy.AmendmentorChangestothePolicy"),
		description: t("privacyPolicy.Description1"),
	},
	{
		id: 2,
		title: t("privacyPolicy.DataCollectionGeneralInformation"),
		description: t("privacyPolicy.Description2"),
	},
	{
		id: 3,
		title: t("privacyPolicy.PersonallyIdentifiableInformation"),
		description: t("privacyPolicy.Description3"),
	},
	{
		id: 4,
		title: t("privacyPolicy.DisclosureonUseofYourInformation"),
		description: t("privacyPolicy.Description4"),
	},
	{
		id: 5,
		title: t("privacyPolicy.Winnings"),
		description: t("privacyPolicy.Description5"),
	},
	{
		id: 6,
		title: t("privacyPolicy.Security"),
		description: t("privacyPolicy.Description6"),
	},
	{
		id: 7,
		title: t("privacyPolicy.Cookies"),
		description: t("privacyPolicy.Description7"),
	},
	{
		id: 8,
		title: t("privacyPolicy.Datadestruction"),
		description: t("privacyPolicy.Description8"),
	},
	{
		id: 9,
		title: t("privacyPolicy.DataAccessandUpdates"),
		description: t("privacyPolicy.Description9"),
	},
	{
		id: 10,
		title: t("privacyPolicy.Contact"),
		description: t("privacyPolicy.Description10"),
	},
]);

const currentId = ref(-1);
const openItem = (id) => (currentId.value = id);
const closeItem = (id) => (currentId.value = -1);

const toggle = (id) => {
	if (currentId.value == id) closeItem(id);
	else openItem(id);
};
</script>

<style scoped lang="sass">
.privacyPolicyContainer
  margin-block: 1rem
.privacyPolicyTitle
  font-size: 1.2em
  font-weight: var(--font-bold)
  text-transform: uppercase
.privacyPolicyDescription
  margin-block: 1rem
.privacyPolicyContent
  white-space: pre-line
  margin-block: 2rem
.privacyPolicyItem
  padding: 0.8rem
  border-bottom: 1px solid var(--border-regular)
  &:first-child
    border-top: 1px solid var(--border-regular)
.privacyPolicyName
  font-weight: var(--font-bold)
  display: flex
  justify-content: space-between
.arrow
  transition: 0.2s
  &.active
    transform: rotate(-180deg)
.privacyPolicyDetails
  margin-top: 0.5rem
</style>
