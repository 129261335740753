import { VendorResponse } from "@/types/Vendor/Response/VendorResponse";
import { VendorAccountResponse } from "@/types/Vendor/Response/VendorAccountResponse";
import { CategoryResponse } from "@/types/Vendor/Response/CategoryResponse";
import {
	TransferableVendorResponse,
	TransferableVendorDetailResponse,
} from "@/types/Vendor/Response/TransferableVendorResponse";

export interface VendorStateInterface {
	vendor: VendorResponse[];
	activeMemberVendor: VendorResponse[];
	vendorAccount: VendorAccountResponse;
	categoryList: CategoryResponse[];
	transferableVendor: TransferableVendorResponse[];
	transferableVendorDetail: TransferableVendorDetailResponse[];
}

function state(): VendorStateInterface {
	return {
		vendor: [] as VendorResponse[],
		activeMemberVendor: [] as VendorResponse[],
		vendorAccount: {} as VendorAccountResponse,
		categoryList: [] as CategoryResponse[],
		transferableVendor: [] as TransferableVendorResponse[],
		transferableVendorDetail: [] as TransferableVendorDetailResponse[],
	};
}

export default state;
