import { ActionTree } from "vuex";
import { RootState } from "@/store/state";
import { ProgressTrackerMutations } from "./mutations";
import { ProgressTrackerInterface } from "./state";

export const actions: ActionTree<ProgressTrackerInterface, RootState> = {
	queueTask(context) {
		let taskQueue = context.rootGetters["progressTracker/getTaskQueue"];
		taskQueue = { ...taskQueue, total: taskQueue.total + 1 };
		context.commit(ProgressTrackerMutations.SET_TASK_QUEUE, taskQueue);
		return taskQueue;
	},

	async completeTask(context, payload) {
		// if you don't need to track success/failure, no payload is required
		// call this function in .finally().
		// otherwise, call this function in .then() and pass { success: true } as payload,
		// or call in .catch() and pass { failed: true } as payload
		let taskQueue = context.rootGetters["progressTracker/getTaskQueue"];
		taskQueue = {
			...taskQueue,
			completed: taskQueue.completed + 1,
			success: payload?.success ? taskQueue.success + 1 : taskQueue.success,
			failed: payload?.failed ? taskQueue.failed + 1 : taskQueue.failed,
		};
		context.commit(ProgressTrackerMutations.SET_TASK_QUEUE, taskQueue);
		return taskQueue;
	},

	clearQueue(context) {
		context.commit(ProgressTrackerMutations.SET_TASK_QUEUE, { total: 0, completed: 0, success: 0, failed: 0 });
		return true;
	},

	setAppLoading(context, payload) {
		context.commit(ProgressTrackerMutations.SET_APP_LOADING, payload);
	},
};
