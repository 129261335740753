import { Module } from "vuex";
import { RootState } from "@/store/state";
import state, { DepositGimmickStateInterface } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export const depositGimmick: Module<DepositGimmickStateInterface, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

