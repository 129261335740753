import { InboxStateInterface } from "./state";
import { MutationTree } from "vuex";
import { MemberInboxResponse } from "@/types/Inbox/Response/MemberInboxResponse";
import { KeywordResponse } from "@/types/Inbox/Response/KeywordResponse";

export enum InboxMutations {
	SET_MEMBER_INBOX = "SET_MEMBER_BANK",
	SET_KEYWORD = "SET_KEYWORD",
	SET_MEMBER_INBOX_LIST = "SET_MEMBER_INBOX_LIST",
	SET_INBOX_UNREAD_COUNT = "SET_INBOX_UNREAD_COUNT",
}

export const mutations: MutationTree<InboxStateInterface> = {
	[InboxMutations.SET_MEMBER_INBOX](state, payload: MemberInboxResponse) {
		state.memberInbox = payload;
	},
	[InboxMutations.SET_KEYWORD](state, payload: KeywordResponse) {
		state.keyword = payload;
	},
	[InboxMutations.SET_MEMBER_INBOX_LIST](state, payload: MemberInboxResponse[]) {
		state.memberInboxList = payload;
	},
	[InboxMutations.SET_INBOX_UNREAD_COUNT](state, payload) {
		state.inboxUnreadCount = payload;
	},
};
