import { DownloadLinksResponse } from "@/types/DownloadLinks/Response/DownloadLinksResponse";

export interface DownloadLinksStateInterface {
	downloadLinksById: DownloadLinksResponse[];
	downloadLinks: DownloadLinksResponse[];
}

function state(): DownloadLinksStateInterface {
	return {
		downloadLinksById: [],
		downloadLinks: [],
	};
}

export default state;
