import { ActionTree } from "vuex";
import { RootState } from "@/store/state";
import { PaymentChannelStateInterface } from "./state";
import PaymentChannel from "@/network/PaymentChannelService/PaymentChannel";
import { PaymentChannelMutations } from "./mutation";

export const actions: ActionTree<PaymentChannelStateInterface, RootState> = {
	async addAutoDeposit(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const ipAddress = context.rootGetters["ipAddress/getIPAddress"];
		return await PaymentChannel.addAutoDepositTicket(
			payload.bankCode,
			parseFloat(payload.amount),
			parseInt(payload.bonusId),
			loginId,
			parseInt(payload.methodId),
			ipAddress,
			payload.method,
			payload.clientPaymentGatewayId
		);
	},
};
