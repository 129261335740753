import { AutoDepositResponse } from "@/types/PaymentChannel/Response/AutoDepositResponse";
import { MutationTree } from "vuex";
import { PaymentChannelStateInterface } from "./state";

export enum PaymentChannelMutations {
	SET_AUTO_DEPOSIT = "SET_AUTO_DEPOSIT",
}

export const mutations: MutationTree<PaymentChannelStateInterface> = {
	[PaymentChannelMutations.SET_AUTO_DEPOSIT](state, payload: AutoDepositResponse) {
		state.autoDeposit = payload;
	},
};
