import { MemberTierResponse, MemberGroupListResponse } from "@/types/MemberGroup/Response/MemberGroupResponse";

export interface MemberGroupStateInterface {
	memberCurrentTier: MemberTierResponse;
	memberGroupList: MemberGroupListResponse;
}

function state(): MemberGroupStateInterface {
	return {
		memberCurrentTier: null as MemberTierResponse,
		memberGroupList: [] as MemberGroupListResponse,
	};
}

export default state;
