export interface TaskQueue {
	total: number;
	completed: number;
	success: number;
	failed: number;
}

export interface ProgressTrackerInterface {
	taskQueue: TaskQueue;
	appLoading: boolean;
}

function state(): ProgressTrackerInterface {
	return {
		taskQueue: { total: 0, completed: 0, success: 0, failed: 0 },
		appLoading: false,
	};
}

export default state;
