import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { PaymentChannelStateInterface } from "./state";
import { AutoDepositResponse } from "@/types/PaymentChannel/Response/AutoDepositResponse";

const timezone = new Date().getTimezoneOffset();

export const getters: GetterTree<PaymentChannelStateInterface, RootState> = {
	getAutoDepositData(state): AutoDepositResponse {
		return state.autoDeposit;
	},
};
