import { GlobalSettingStateInterface } from "./state";
import { GlobalSettingResponse } from "@/types/GlobalSetting/Response/GlobalSettingResponse";
import { GetLinkResponse } from "@/types/GlobalSetting/Response/GetLinkResponse";
import { MutationTree } from "vuex";

export enum GlobalSettingMutations {
	SET_GLOBAL_SETTING = "SET_GLOBAL_SETTING",
	SET_CURRENT_TIME = "SET_CURRENT_TIME",
	SET_LINK_SETTING = "SET_LINK_SETTING",
}

export const mutations: MutationTree<GlobalSettingStateInterface> = {
	[GlobalSettingMutations.SET_GLOBAL_SETTING](state, payload: GlobalSettingResponse[]) {
		state.globalSetting.push(...payload);
	},

	[GlobalSettingMutations.SET_CURRENT_TIME](state, payload: string) {
		state.currentTime = payload;
	},

	[GlobalSettingMutations.SET_LINK_SETTING](state, payload: GetLinkResponse[]) {
		state.getLink = payload;
	},
};
