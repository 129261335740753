import { ref, onMounted } from "vue";
import { useToast } from "vue-toastification";
import i18n from "@/resource/index";

const { t } = i18n.global;
const toast = useToast();
const onlineToast = ref(null);
const isOnline = ref(false);

const checkIsOnline = () => {
	isOnline.value = navigator.onLine;
	if (!isOnline.value) {
		onlineToast.value = toast.info(() => t("toast.toastOffline"), {
			timeout: false,
			closeOnClick: false,
			pauseOnFocusLoss: false,
			pauseOnHover: false,
			draggable: false,
			draggablePercent: 0.6,
			showCloseButtonOnHover: false,
			hideProgressBar: false,
			closeButton: false,
			icon: true,
			rtl: false,
		});
	} else {
		toast.dismiss(onlineToast.value);
	}
};

const initCheckConnection = () => {
	checkIsOnline();
	window.addEventListener("online", checkIsOnline);
	window.addEventListener("offline", checkIsOnline);
};

initCheckConnection();
