<template>
	<div class="dropdownItem cursorPointer">
		<slot> </slot>
	</div>
</template>

<style scoped lang="sass">
.dropdownItem
  position: relative
  display: flex
  justify-content: start
  align-items: center
  gap: 1rem
  padding: 1rem
  overflow: hidden
  &:hover::before
    content: ''
    position: absolute
    height: 100%
    width: 100%
    top: 0
    left: 0
    background: var(--dropdown-background-hover)
    animation: slide
    animation-duration: 0.2s
    animation-iteration-count: 1
    z-index: -1
.router-link-active .dropdownItem
  color: var(--text-accent)
</style>
