// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/icons/chevron-down-solid.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button.inputSelect[data-v-47f52531]{text-align:start;font-weight:var(--font-regular);background:var(--input-background);color:var(--text-regular);border-radius:4px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:.6rem;background-position:right 1rem center;cursor:default}.placeholder[data-v-47f52531]{color:var(--text-regular);background:var(--transparent);vertical-align:initial}.option[data-v-47f52531],.select[data-v-47f52531]{height:100%;display:flex;align-items:center;gap:.5rem}.option[data-v-47f52531]{height:2.5rem;padding:0 1rem;color:var(--text-regular)}.option.focus[data-v-47f52531],.option[data-v-47f52531]:hover{background:var(--dropdown-background-hover)}.nullOption[data-v-47f52531],.option.disabled[data-v-47f52531]{opacity:.25}.optionText[data-v-47f52531]{padding-right:1rem}.optionText.active[data-v-47f52531]{color:var(--text-accent)}.optionImage[data-v-47f52531]{height:2rem;width:3rem;-o-object-fit:contain;object-fit:contain}[data-v-47f52531] .dropdownContainer .dropdownPanel{max-height:50vh}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
