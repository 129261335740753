import { ActionTree } from "vuex";
import eventService from "@/network/EventService/Event";
import paymentService from "@/network/PaymentService/Payment";
import betsService from "@/network/BetsService/Bets";
import { EventStateInterface } from "./state";
import { RootState } from "@/store/state";
import { EventMutations } from "./mutations";
import { EventClaimedStatus } from "@/common/EventClaimedStatus";
import { RecheckInMissionType } from "@/common/RecheckInMissionType";
import moment from "moment";

export const actions: ActionTree<EventStateInterface, RootState> = {
	async fetchEventList(context, payload) {
		const res = await eventService.getEvent();
		context.commit(EventMutations.SET_EVENT_LIST, res);
	},
	async fetchMemberLoginEvent(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await eventService.getMemberLoginEvent(memberId);
		context.commit(EventMutations.SET_MEMBER_LOGIN_EVENT, res);
		const eventRewardByDayList = [];
		if (res) {
			context.commit(EventMutations.SET_CHECK_IN_EVENT_START, true);
			//filter event claimed record by day
			const eventClaimedRecordListByDay =
				res?.eventClaimedRecord.reduce((acc, item) => {
					const seqObject = acc.find((accItem) => accItem.sequence == item.sequence);
					if (!seqObject) return [...acc, { sequence: item.sequence, eventClaimed: [item] }];
					else
						return acc.map((accItem) => {
							if (accItem.sequence == item.sequence) seqObject.eventClaimed.push(item);
							return accItem;
						});
				}, []) || [];

			//filter event reward by day
			if (res.event != null || res.event != undefined) {
				const eventList = res.event.eventReward.sort(function (a, b) {
					return a.sequence - b.sequence;
				});
				for (let i = 1; i <= res.event.targetDays; i++) {
					const eventRewardItem = {
						sequence: i,
						eventReward: res.event.eventReward.filter((x) => x.sequence == i),
						isClaimed: i <= eventClaimedRecordListByDay.length,
						claimedDate:
							i <= eventClaimedRecordListByDay.length
								? eventClaimedRecordListByDay.find((x) => x.sequence == i).eventClaimed[0].created
								: null,
					};
					eventRewardByDayList.push(eventRewardItem);
				}
			}

			context.commit(EventMutations.SET_EVENT_REWARD_BY_DAY, eventRewardByDayList);
			context.commit(EventMutations.SET_EVENT_CLAIMED_BY_DAY, eventClaimedRecordListByDay);
		}
	},
	async fetchMemberRecheckIn(context, payload) {
		const eventId = context.rootGetters["events/getMemberLoginEvent"].event.id;
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await eventService.getMemberRecheckIn(eventId, memberId);
		context.commit(EventMutations.SET_MEMBER_RECHECKIN, res);
	},
	async addEventClaim(context, payload) {
		const eventId = context.rootGetters["events/getMemberLoginEvent"].event.id;
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const claimResult = { succeeded: false, errorCode: "" };
		const eventClaimed = await eventService.addEventClaimed(memberId, eventId, payload.eventRewardIds);

		if (!eventClaimed) {
			claimResult.errorCode = "Failed to Create Event Claim";
			return claimResult;
		}
		if (eventClaimed.error != null || eventClaimed.succeeded == false) {
			claimResult.errorCode = eventClaimed.errorCode;
			return claimResult;
		}

		const eventClaimedIds = {};
		eventClaimed.eventClaimedIds.forEach((x) => {
			eventClaimedIds[x] = Number(EventClaimedStatus["Claimed"]);
		});
		const updateEventResult = await context.dispatch("updateEventClaim", { eventClaimedIds: eventClaimedIds });
		return updateEventResult;
	},
	async updateEventClaim(context, payload) {
		const res = await eventService.updateEventClaimed(payload.eventClaimedIds);
		return res;
	},
	async fetchMemberAddOnBonus(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await eventService.getMemberAddOnBonus(memberId, 0);
		context.commit(EventMutations.SET_MEMBER_ADD_ON_BONUS, res);
	},
	async fetchUserDepositAmount(context, payload) {
		const event = context.rootGetters["events/getMemberLoginEvent"];
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const timezone = event.timezone;
		const currentDate = event.todayDate;
		// const searchDateFrom = moment(currentDate).startOf("day").toDate();
		// const searchDateTo = moment(currentDate).startOf("day").toDate();
		const searchDateFrom = currentDate.slice(0, 10);
		const searchDateTo = currentDate.slice(0, 10);
		const memberIds = [];

		memberIds.push(memberId);
		if (payload.missionType == RecheckInMissionType.Deposit) {
			const getUserDepositAmountResponse = await paymentService.getUserDepositAmount(
				memberIds,
				searchDateFrom,
				searchDateTo
			);
			context.commit(EventMutations.SET_USER_DEPOSIT_AMOUNT, getUserDepositAmountResponse);
		} else if (payload.missionType == RecheckInMissionType.Turnover) {
			const getUserTurnoverAmount = await betsService.getUserTurnoverAmount(memberIds, searchDateFrom, searchDateTo);
			context.commit(EventMutations.SET_USER_DEPOSIT_AMOUNT, getUserTurnoverAmount);
		}
	},
	async addMemberRecheckIn(context, payload) {
		const eventId = context.rootGetters["events/getMemberLoginEvent"].event.id;
		const memberId = context.rootGetters["identityServer/getMemberId"];
		return await eventService.addMemberRecheckIn(memberId, eventId, payload.eventRewardIds);
	},
};
