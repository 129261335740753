import { AnnouncementStateInterface } from "./state";
import { RunningTextResponse } from "@/types/Announcement/Response/RunningTextResponse";
import { AnnouncementResponse, AnnouncementPopUpResponse } from "@/types/Announcement/Response/AnnouncementResponse";
import { MutationTree } from "vuex";

export enum AnnouncementMutations {
	SET_RUNNING_TEXT = "SET_RUNNING_TEXT",
	SET_ANNOUNCEMENT = "SET_ANNOUNCEMENT",
	SET_ANNOUNCEMENT_POPUP = "SET_ANNOUNCEMENT_POPUP",
}

export const mutations: MutationTree<AnnouncementStateInterface> = {
	[AnnouncementMutations.SET_RUNNING_TEXT](state, payload: RunningTextResponse[]) {
		state.runningText = payload;
	},
	[AnnouncementMutations.SET_ANNOUNCEMENT](state, payload: AnnouncementResponse[]) {
		state.announcement = payload;
	},
	[AnnouncementMutations.SET_ANNOUNCEMENT_POPUP](state, payload: AnnouncementPopUpResponse[]) {
		state.announcementPopUp = payload;
	},
};
