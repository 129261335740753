import { DownloadLinksStateInterface } from "./state";
import { DownloadLinksResponse } from "@/types/DownloadLinks/Response/DownloadLinksResponse";
import { MutationTree } from "vuex";

export enum DownloadLinksMutations {
	SET_DOWNLOAD_LINKS_BY_ID = "SET_DOWNLOAD_LINKS_BY_ID",
	SET_DOWNLOAD_LINKS = "SET_DOWNLOAD_LINKS",
}

export const mutations: MutationTree<DownloadLinksStateInterface> = {
	[DownloadLinksMutations.SET_DOWNLOAD_LINKS_BY_ID](state, payload: DownloadLinksResponse[]) {
		state.downloadLinksById.push(...payload);
	},

	[DownloadLinksMutations.SET_DOWNLOAD_LINKS](state, payload: DownloadLinksResponse[]) {
		state.downloadLinks = payload;
	},
};
