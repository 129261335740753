<template>
	<div class="dropdownContainer" @mouseover="hover = true" @mouseleave="hover = false">
		<div class="toggleContainer" @click="handleClick" ref="toggle">
			<!-- WHEN USING THIS SLOT, REMEMBER TO ADD tabindex="0" TO THE TOGGLE -->
			<slot name="toggle"> </slot>
		</div>

		<Transition name="dropdown">
			<div
				v-show="isOpen"
				class="dropdownPanel noScrollBar"
				:class="{ floatLeft: floatLeft, floatRight: floatRight }"
				@blur="(event) => handleBlur(event)"
				ref="dropdown"
				tabindex="-1"
			>
				<slot name="dropdown"> </slot>
			</div>
		</Transition>
	</div>
</template>

<script setup>
import { ref, watch, nextTick, defineProps, defineEmits, defineExpose } from "vue";
import { useIsMobile } from "@/composables/useMobile";
import { hasTouch } from "@/composables/usePlatform";

const emit = defineEmits(["open", "close"]);

const props = defineProps({
	toggleByHover: {
		type: Boolean,
		default: false,
	},
	floatLeft: {
		type: Boolean,
		default: false,
	},
	floatRight: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const dropdown = ref(null);
const toggle = ref(null);
const isOpen = ref(false);
const hover = ref(false);

const handleClick = () => {
	if (!props.toggleByHover && !props.disabled) {
		toggleDropdown();
	}
};

const handleBlur = (event) => {
	// if (hasTouch.value) return;
	if (!props.toggleByHover && !toggle.value?.contains(event.relatedTarget)) {
		document.addEventListener("mouseup", onMouseUp);
		document.addEventListener("touchend", onMouseUp);
	}
	function onMouseUp() {
		toggleDropdown("close");
		document.removeEventListener("mouseup", onMouseUp);
		document.removeEventListener("touchend", onMouseUp);
	}
};

const toggleDropdown = (action = null) => {
	if (action == "close") isOpen.value = false;
	else if (action == "open") isOpen.value = true;
	else isOpen.value = !isOpen.value;
};

const focusToggle = () => {
	toggle.value?.children?.[0]?.focus();
};

const focusDropdown = () => {
	dropdown.value.focus();
};

watch(isOpen, async (newValue, oldValue) => {
	await nextTick();
	if (oldValue == false && newValue == true) {
		emit("open");
		focusDropdown();
	}
	if (oldValue == true && newValue == false) {
		emit("close");
		focusToggle();
	}
});

watch(hover, (newValue, oldValue) => {
	if (props.toggleByHover) {
		if (oldValue == false && newValue == true) isOpen.value = true;
		if (oldValue == true && newValue == false) isOpen.value = false;
	}
});

defineExpose({ toggleDropdown, isOpen });
</script>

<style scoped lang="sass">
.dropdownContainer
	position: relative
	height: 100%
	.toggleContainer
		display: contents
		height: 100%
	.dropdownPanel
		position: absolute
		width: 100%
		min-width: 10rem
		max-height: 80vh
		background: var(--dropdown-background)
		box-shadow: var(--box-shadow)
		padding-block: 0.8rem
		border-radius: 4px
		overflow: auto
		z-index: 29
		&.floatRight
			left: 0
		&.floatLeft
			right: 0
		&:focus-visible, &:focus
			border: none
			outline: none
</style>
