import httpClient, { ClientId } from "./HttpClient";
import { RunningTextResponse } from "@/types/Announcement/Response/RunningTextResponse";
import { AnnouncementResponse, AnnouncementPopUpResponse } from "@/types/Announcement/Response/AnnouncementResponse";

const getRunningText = (status: string, runningTextCategory: string): Promise<RunningTextResponse[]> => {
	const endPoint = "/api/Announcement/RunningText";
	const params = {
		status: status,
		runningTextCategory: runningTextCategory,
		ClientId: ClientId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getAnnouncement = (remark: string): Promise<AnnouncementResponse> => {
	const endPoint = "/api/Announcement/";
	const params = {
		remark: remark,
		ClientId: ClientId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getAnnouncementPopUp = (memberId: string, langId: number): Promise<AnnouncementPopUpResponse[]> => {
	const endPoint = "/api/Announcement/PopUp";
	const params = {
		langId: langId,
		memberId: memberId,
		ClientId: ClientId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export { getRunningText, getAnnouncement, getAnnouncementPopUp };
