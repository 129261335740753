import httpClient from "./HttpClient";

const getIpAddress = (): Promise<any> => {

	//Added the endpoint 
	const endPoint = "https://www.cloudflare.com/cdn-cgi/trace";
	return httpClient
		.get(endPoint) //new endpoint https://www.cloudflare.com/cdn-cgi/trace
		.then((response) => {
			const lines = response.data.split('\n');
			for (const line of lines) {
				const [key, value] = line.split('=');
				if (key === 'ip') {
					return value;
				}
			}
			return "1.1.1.1";
		})
		.catch(() => "1.1.1.1");
};

export { getIpAddress };
