<!-- Use this component for all links/modals -->

<template>
	<span v-if="showLoginModal" v-b-modal="login.modalName" class="unifiedLink" :class="{ cursorPointer: !disabled }">
		<slot> </slot>
	</span>

	<span v-else-if="showBankModal" @click="openBankInfoPage" class="unifiedLink" :class="{ cursorPointer: !disabled }">
		<slot> </slot>
	</span>

	<router-link
		v-else-if="showRouteLink"
		:to="{ path: link, query: routerQuery }"
		class="unifiedLink"
		:class="{ cursorPointer: !disabled }"
	>
		<slot> </slot>
	</router-link>

	<span v-else-if="showModal" v-b-modal="modalName" class="unifiedLink" :class="{ cursorPointer: !disabled }">
		<slot> </slot>
	</span>

	<a v-else-if="showLink" :href="link" class="unifiedLink" :class="{ cursorPointer: !disabled }" target="_blank">
		<slot></slot>
	</a>

	<div v-else class="unifiedLink" :class="{ cursorPointer: !disabled }" @click="emit('linkClick')">
		<slot></slot>
	</div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { isLogin } from "@/composables/useAuth";
import store from "@/store/index";
import { routeData } from "@/constant";

const emit = defineEmits(["linkClick"]);
const router = useRouter();
const route = useRoute();

const login = routeData.find((item) => item.title == "login");
const bankInfo = routeData.find((item) => item.title == "bankinfo");

const props = defineProps({
	isRouteLink: {
		type: Boolean,
		default: false,
	},
	link: {
		type: [Object, String],
		default: null,
	},
	routerQuery: {
		type: Object,
		default: null,
	},
	isModal: {
		type: Boolean,
		default: false,
	},
	isEvent: {
		type: Boolean,
		default: false,
	},
	modalName: {
		type: String,
		default: null,
	},
	validateLogin: {
		type: Boolean,
		default: false,
	},
	validateBank: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

// ================= Modal Control Start =================

// const openModal = (modalName) => {
// 	store.dispatch("modal/openModal", modalName);
// };

// ================= Modal Control End =================

// ================= Show Control Start =================

const showLoginModal = computed(() => {
	return props.validateLogin && !isLogin.value && !props.disabled;
});

const openLoginPage = () => {
	router.replace({ path: login.link, query: { redirect: encodeURIComponent(props.link) } });
};

const openBankInfoPage = () => {
	router.replace({ path: bankInfo.link, query: { redirect: encodeURIComponent(props.link) } });
};

const showBankModal = computed(() => {
	return props.validateBank && !memberBankExists.value && !props.disabled;
});

const showRouteLink = computed(() => {
	return props.isRouteLink && props.link && !props.disabled;
});

const showModal = computed(() => {
	return props.isModal && props.modalName && !props.disabled;
});

const showEvent = computed(() => {
	return props.isEvent && !props.disabled;
});

const showLink = computed(() => {
	return props.link && !props.disabled;
});

const memberBankExists = computed(() => store.getters["member/getIsMemberBankExist"]);

// ================= Show Control End =================
</script>

<style scoped lang="sass">
.unifiedLink, .unifiedLink:hover
  display: contents
  text-decoration: none
  font-weight: revert
  margin: revert
</style>
