import { ref, computed } from "vue";
import { useLoading } from "vue-loading-overlay";
import { useToast } from "vue-toastification";
import { toastOptionSuccess, toastOptionError } from "@/composables/useToastOptions";
import router from "@/router";
import i18n from "@/resource/index";
import store from "@/store/index";

const { t } = i18n.global;
const toast = useToast();
const $loading = useLoading();

// ================= Main Wallet Start =================

const fetchMemberBalance = async () => {
	queueFetch();
	await store.dispatch("member/fetchMember").finally(() => completeFetch());
};

// ================= Main Wallet End =================

// ================= Vendors Start =================

const vendorList = computed(() => store.getters["vendors/getActiveMemberVendor"]());
const fetchMemberActiveVendorList = async () => {
	queueFetch();
	await store.dispatch("vendors/fetchMemberActiveVendorList").finally(() => completeFetch());
};

const updateVendorBalanceSequential = async (specifiedVendors = []) => {
	if (specifiedVendors.length > 0)
		await Promise.allSettled(
			specifiedVendors.map(async (vendor) => await updateVendorBalance(vendor.vendorAccountId))
		);
	else
		await Promise.allSettled(
			vendorList.value.map(async (vendor) => await updateVendorBalance(vendor.vendorAccountId))
		);
};

const updateVendorBalance = async (vendorAccountId) => {
	if (vendorAccountId == "MAIN") return;
	queueFetch();
	const res = await store
		.dispatch("memberAccount/fetchVendorBalance", {
			vendorAccountId: vendorAccountId,
			isFromDB: false,
		})
		.finally(() => completeFetch());
	return res?.balance;
};

// ================= Vendors End =================

// ================= Vendor Detail Start =================

const fetchTransferableVendorDetail = async () => {
	queueFetch();
	await store
		.dispatch("vendors/fetchTransferableVendorDetail", {
			vendorName: "MAIN",
		})
		.finally(() => completeFetch());
};

// ================= Vendor Detail End =================

// ================= Turnover/Winover Start =================

const loginId = computed(() => store.getters["identityServer/getLoginId"]);

const fetchMemberTurnoverWinover = async () => {
	queueFetch();
	store
		.dispatch("memberAccount/fetchMemberTurnover", {
			loginId: loginId,
			isFromDB: true,
		})
		.finally(() => completeFetch());
	queueFetch();
	store
		.dispatch("memberAccount/fetchMemberWinover", {
			loginId: loginId,
			isFromDB: true,
		})
		.finally(() => completeFetch());
};

// ================= Turnover/Winover End =================

// ================= Bonus Start =================

const fetchActiveBonusAppliedWithBonusVendor = async () => {
	queueFetch();
	await store.dispatch("bonus/fetchActiveBonusAppliedWithBonusVendor").finally(() => completeFetch());
};

// ================= Bonus End =================

// ================= Refresh Start =================

const refreshAllBalance = async (specifiedVendors = []) => {
	await fetchMemberBalance();
	fetchMemberTurnoverWinover();
	await fetchMemberActiveVendorList();
	await updateVendorBalanceSequential(specifiedVendors);
	fetchActiveBonusAppliedWithBonusVendor();
	fetchTransferableVendorDetail();
};

// ================= Refresh End =================

// ================= Restore Start =================

const restoreBalanceToMain = async () => {
	const loader = $loading.show();

	queueFetch();
	const res = await store
		.dispatch("payment/vendorsBalanceAndTransfer", { vendorTo: "MAIN" })
		.then((res) => {
			setModalParam();
			refreshAllBalance();
			return res;
		})
		.catch((err) => {
			toast.error(t("toast.transferFailed", { error: err?.response?.data.title }), toastOptionError);
		})
		.finally(() => completeFetch());

	loader.hide();
};

const MODAL_NAME = "transferSummary";
const setModalParam = () => {
	router.replace({ query: { ...router.currentRoute.value.query, modal: MODAL_NAME } });
};
// const unsetModalParam = () => {
// 	const { modal, ...params } = route.query;
// 	router.replace({ query: params });
// };

// ================= Restore End =================

// ================= Others Start =================

const queueFetch = () => store.dispatch("progressTracker/queueTask");
const completeFetch = () => store.dispatch("progressTracker/completeTask");

// ================= Others End =================

export { restoreBalanceToMain, refreshAllBalance };
