import { ActionTree } from "vuex";
import { getLaunchGameUrl, getGameList, getGameCategories, getGamePopularity } from "@/network/GamesService/Games";
import { GamesStateInterface } from "./state";
import { RootState } from "@/store/state";
import { GamesMutations } from "./mutations";
import PrivacyPolicyPage from "@/pages/information/PrivacyPolicyPage.vue";

export const actions: ActionTree<GamesStateInterface, RootState> = {
	async fetchLaunchGameUrl(context, payload) {
		// const vendorId = context.rootGetters["vendors/getVendor"].id;
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await getLaunchGameUrl(
			payload.vendorId,
			memberId,
			payload.GameType,
			payload.Lang,
			payload.gameCode,
			false,
			payload.isMobile
		);
		return res;
	},
	async fetchGameList(context, payload) {
		const vendorId = context.rootGetters["vendors/getVendor"].id;
		const res = await getGameList(payload.id, payload.GameCategory);
		context.commit(GamesMutations.SET_GAMES_LIST, res);
		return res;
	},
	async fetchGameCategory(context, payload) {
		const vendorId = context.rootGetters["vendors/getVendor"].id;
		let res = await getGameCategories(payload.id);
		res = res.sort();
		return res;
	},

	checkVendorName(context, payload) {
		if (payload.Category == "LC") {
			switch (payload.VendorName.toString().toUpperCase()) {
				case "PRAGMATICAWC": {
					return "PP-LIVE-001";
				}
				case "PRAGMATIC": {
					return "101";
				}
				case "PLAYTECHDH": {
					return "ubal";
				}
				case "SEXYBACCARAT": {
					return "MX-LIVE-001";
				}
				default: {
					return payload?.GameCode || "";
				}
			}
		} else {
			return payload?.GameCode || "";
		}
	},
	async fetchGamesPopularity(context, payload) {
		const res = await getGamePopularity(10);
		context.commit(GamesMutations.SET_GAMES_POPULARITY, res);
		return res;
	},
};
