import { RunningTextResponse } from "@/types/Announcement/Response/RunningTextResponse";
import { AnnouncementResponse, AnnouncementPopUpResponse } from "@/types/Announcement/Response/AnnouncementResponse";

export interface AnnouncementStateInterface {
	runningText: RunningTextResponse[];
	announcement: AnnouncementResponse[];
	announcementPopUp: AnnouncementPopUpResponse[];
}

function state(): AnnouncementStateInterface {
	return {
		runningText: [],
		announcement: [],
		announcementPopUp: [],
	};
}

export default state;
