import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import state, { VendorStateInterface } from "./state";
import { VendorResponse } from "@/types/Vendor/Response/VendorResponse";
import { VendorAccountResponse } from "@/types/Vendor/Response/VendorAccountResponse";
import { CategoryResponse } from "@/types/Vendor/Response/CategoryResponse";
import {
	TransferableVendorResponse,
	TransferableVendorDetailResponse,
} from "@/types/Vendor/Response/TransferableVendorResponse";

export const getters: GetterTree<VendorStateInterface, RootState> = {
	getVendor(state): VendorResponse[] {
		return state.vendor;
	},
	getActiveMemberVendor:
		(state) =>
		(vendorName: string): VendorResponse[] => {
			if (vendorName) {
				return state.activeMemberVendor.filter((vendor) => vendor.name == vendorName);
			} else return state.activeMemberVendor;
		},
	getVendorAccount(state): VendorAccountResponse {
		return state.vendorAccount;
	},
	getCategory:
		(state) =>
		(categoryName: string): CategoryResponse => {
			return state.categoryList.filter((category) => category.name == categoryName)[0];
		},
	getCategoryList(state): CategoryResponse[] {
		return state.categoryList.filter((category) => category.status == true);
	},
	getTransferableVendor(state): TransferableVendorResponse[] {
		return state.transferableVendor;
	},
	getTransferableVendorDetail(state): TransferableVendorDetailResponse[] {
		return state.transferableVendorDetail;
	},
};
