export default {
	faq: [
		{
			q: "Apa itu Win 'n Go+?",
			a: "Win 'n Go+ ialah ciri dompet khas yang membolehkan baki anda memperoleh faedah harian. Ia menawarkan anda peluang untuk mengembangkan baki anda dari semasa ke semasa.",
		},
		{
			q: "Bagaimana Win 'n Go+ berfungsi?",
			a: "Baki anda dalam dompet Win 'n Go+ akan memperoleh faedah harian. Anda boleh masukan atau keluarkan amaun dari dompet ini, tetapi terdapat syarat dan prosedur tertentu untuk setiap transaksi.",
		},
		{
			q: "Bagaimanakah saya memperoleh faedah harian dengan Win 'n Go+?",
			a: "Baki anda dalam dompet Win 'n Go+ akan memperoleh faedah harian secara automatik. Faedah akan dikreditkan ke akaun Win 'n Go+ anda setiap hari berdasarkan baki dalam dompet Win 'n Go+ anda. Masa pemotongan harian untuk mandapatkan baki semasa untuk pengiraan faedah ialah 00:00.",
		},
		{
			q: "Apakah itu Dompet Berkunci, dan mengapa baki saya dipindahkan ke sana apabila saya mengeluarkan wang daripada Win 'n Go+?",
			a: "Dompet Berkunci ialah tempat dimana faedah anda dikeluarkan daripada dompet Win 'n Go+, apabila anda mengeluarkan wang. Untuk mengeluarkan dana daripada Dompet Berkunci, anda akan tertakluk kepada 5 kali turnover daripada amaun faedah yang dikeluarkan. Ini adalah langkah keselamatan untuk memastikan permainan yang bertanggungjawab.",
		},
		{
			q: "Adakah jumlah maksimum yang boleh saya miliki dalam dompet Win 'n Go+ saya?",
			a: "Jumlah maksimum yang boleh disimpan dalam dompet Win 'n Go+ anda akan tertakluk pada terma dan syarat faedah aktif. Apabila had ini dicapai, tiada lagi faedah harian akan dikreditkan ke akaun anda.",
		},
		{
			q: "Berapakah jumlah pemindahan minimum untuk deposit ke dalam dompet Win 'n Go+?",
			a: "Jumlah pemindahan minimum untuk deposit ke dompet Win 'n Go+ ialah {minAmount}.",
		},
		{
			q: "Apakah yang perlu saya lakukan jika saya menghadapi sebarang masalah dengan dompet Win 'n Go+ saya?",
			a: "Jika anda menghadapi sebarang masalah atau mempunyai soalan tentang dompet Win 'n Go+ anda, sila dapatkan bantuan daripada pasukan perkhidmatan pelanggan kami melalui Live Chat. Mereka akan membantu untuk menyelesaikan sebarang kebimbangan dan masalah anda.",
		},
		{
			q: "Bagaimanakah saya boleh deposit ke dompet Win 'n Go+ saya?",
			a: "Untuk deposit ke dompet Win 'n Go+ anda, hanya ketik pada bahagian 'Win 'n Go+' daripada skrin utama platform kami. Kemudian, pilih 'Amaun masuk', masukkan amaun yang dikehendaki dan ikuti langkah untuk menyerahkan borang.",
		},
		{
			q: "Bolehkah saya mengeluarkan dana terus daripada dompet Win 'n Go+ saya?",
			a: "Ya, jika anda belum memperoleh apa-apa faedah lagi. Dalam kes ini, jumlah prinsipal anda akan dipindahkan terus ke dalam Dompet Utama anda dan anda akan dapat membuat pengeluaran.\nTidak, jika Win 'n Go+ anda sudah memperoleh faedah. Anda perlu memastikan tiada turnover yang tinggal dalam akaun anda. Jumlah prinsipal anda masih akan dipindahkan terus ke dalam Dompet Utama anda. Walau bagaimanapun, faedah yang anda perolehi akan dikreditkan ke Dompet Berkunci anda dan tertakluk pada keperluan turnover yang tertentu.",
		},
	],
};
