import httpClient, { ClientId } from "./HttpClient";
import { DepositGimmicksResponse } from "@/types/DepositGimmicks/Response/DepositGimmicksResponse";

const getDepositGimmick = (): Promise<DepositGimmicksResponse[]> => {
    const endPoint = `/api/DepositGimmicks/GetDepositGimmicks`;
    const params = {
        clientId: ClientId,
    };
    return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export { getDepositGimmick }