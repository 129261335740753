import { MemberAccountStateInterface } from "./state";
import { MemberVendorBalanceResponse } from "@/types/MemberAccount/Response/MemberVendorBalanceResponse";
import { MemberWinoverResponse } from "@/types/MemberAccount/Response/MemberWinoverResponse";
import { MemberTurnoverResponse } from "@/types/MemberAccount/Response/MemberTurnoverResponse";
import { MemberAccountResponse } from "@/types/MemberAccount/Response/MemberAccountResponse";
import { MemberWalletResponse } from "@/types/MemberAccount/Response/MemberWalletResponse";
import { MutationTree } from "vuex";

export enum MemberAccountMutations {
	SET_VENDOR_BALANCE = "SET_VENDOR_BALANCE",
	SET_MEMBER_WINOVER = "SET_MEMBER_WINOVER",
	SET_MEMBER_TURNOVER = "SET_MEMBER_TURNOVER",
	SET_MEMBER_ACCOUNT = "SET_MEMBER_ACCOUNT",
	SET_MEMBER_BANK_STATUS = "SET_MEMBER_BANK_STATUS",
	SET_MEMBER_WALLET = "SET_MEMBER_WALLET",
	SET_LATEST_WALLET_TIME = "SET_LATEST_WALLET_TIME",
	RESET_MEMBER_ALL = "RESET_MEMBER_ALL",
}

export const mutations: MutationTree<MemberAccountStateInterface> = {
	[MemberAccountMutations.SET_VENDOR_BALANCE](state, payload: MemberVendorBalanceResponse) {
		state.balance = payload.balance;
	},
	[MemberAccountMutations.SET_MEMBER_TURNOVER](state, payload: MemberTurnoverResponse) {
		state.memberTurnover = payload;
	},
	[MemberAccountMutations.SET_MEMBER_WINOVER](state, payload: MemberWinoverResponse) {
		state.memberWinover = payload;
	},
	[MemberAccountMutations.SET_MEMBER_ACCOUNT](state, payload: MemberAccountResponse) {
		state.memberAccount = payload;
	},
	[MemberAccountMutations.SET_MEMBER_WALLET](state, payload: MemberWalletResponse) {
		state.memberWallet = payload;
	},
	[MemberAccountMutations.SET_LATEST_WALLET_TIME](state, payload: string) {
		state.memberWalletUpdateTime = payload;
	},
	[MemberAccountMutations.RESET_MEMBER_ALL](state) {
		state.balance = 0;
		state.memberTurnover = undefined;
		state.memberWinover = undefined;
		state.memberAccount = undefined;
		state.memberWallet = undefined;
	},
};
