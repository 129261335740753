import { ActionTree } from "vuex";
import { livaDataStateInterface } from "./state";
import { RootState } from "@/store/state";
import { livaDataMutations } from "./mutations";
import { fetchLiveTransactions } from "@/network/LiveDataService/LiveData";

export const actions: ActionTree<livaDataStateInterface, RootState> = {
	updateProgressiveJackpot(context, payload) {
		context.commit(livaDataMutations.SET_PROGRESSIVE_JACKPOT_VALUE, payload.jackpot);
	},
	async fetchLiveTransactionList(context, payload) {
		if (payload.transactionType == "Deposit") {
			const currentList = context.rootGetters["liveData/getLiveDepositList"];
			const res = await fetchLiveTransactions(payload.transactionType);
			if (currentList.length == 0) context.commit(livaDataMutations.SET_LIVE_DEPOSIT, res);
			if (currentList.length > 0) {
				const newList = [...res.filter((item) => item.status == 1), ...currentList].slice(0, 10);
				context.commit(livaDataMutations.SET_LIVE_DEPOSIT, newList);
			}
			return res;
		}
		if (payload.transactionType == "Withdrawal") {
			const currentList = context.rootGetters["liveData/getLiveWithdrawalList"];
			const res = await fetchLiveTransactions(payload.transactionType);
			if (currentList.length == 0) context.commit(livaDataMutations.SET_LIVE_WITHDRAWAL, res);
			if (currentList.length > 0) {
				const newList = [...res.filter((item) => item.status == 1), ...currentList].slice(0, 10);
				context.commit(livaDataMutations.SET_LIVE_WITHDRAWAL, newList);
			}
			return res;
		}
	},
};
