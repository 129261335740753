import { computed } from "vue";
import { Platform } from "quasar";

const html = document.querySelector("html");
const body = document.querySelector("body");

const isIOS = computed(
	() => (Platform.is.iphone || Platform.is.ipad) && !Platform.is.cordova && !Platform.is.capacitor
);

const isAndroid = computed(() => Platform.is.android && !Platform.is.cordova && !Platform.is.capacitor);

const isMobileBrowser = computed(() => Platform.is.mobile && !Platform.is.cordova && !Platform.is.capacitor);

const hasTouch = computed(() => Platform.has.touch);

const isDesktop = computed(() => Platform.is.desktop);

const isStandalone = () => {
	const webappsProtocols = ["file:", "cordova:", "capacitor:"];
	return (
		window.matchMedia("(display-mode: standalone)").matches ||
		(window.location && window.location.protocol && webappsProtocols.indexOf(window.location.protocol) !== -1)
	);
};

const detectIOSNavGesture = () => {
	window.meta = {
		leftEdge: 0,
		swipeFromLeft: 0,
		swipeBack: false,
		rightEdge: 0,
		swipeFromRight: 0,
		swipeForward: false,
	};

	window.addEventListener("touchstart", function (e) {
		const touchPoint = e.touches[0].clientX;
		if (touchPoint / window.innerWidth <= 0.1) {
			window.meta = {
				leftEdge: touchPoint || 1,
				swipeFromLeft: 0,
				swipeBack: false,
				rightEdge: 0,
				swipeFromRight: 0,
				swipeForward: false,
			};
		} else if (touchPoint / window.innerWidth >= 0.9) {
			window.meta = {
				leftEdge: 0,
				swipeFromLeft: 0,
				swipeBack: false,
				rightEdge: touchPoint,
				swipeFromRight: 0,
				swipeForward: false,
			};
		} else {
			window.meta = {
				leftEdge: 0,
				swipeFromLeft: 0,
				swipeBack: false,
				rightEdge: 0,
				swipeFromRight: 0,
				swipeForward: false,
			};
		}
	});

	window.addEventListener("touchmove", function (e) {
		const dragPoint = e.touches[0].clientX;
		if (window.meta.leftEdge && dragPoint - window.meta.leftEdge >= 5 && !window.meta.swipeFromLeft) {
			window.meta.swipeFromLeft = dragPoint;
			window.meta.swipeBack = true;
		}
		if (window.meta.rightEdge && window.meta.rightEdge - dragPoint >= 5 && !window.meta.swipeFromRight) {
			window.meta.swipeFromRight = dragPoint;
			window.meta.swipeForward = true;
		}
	});
};

const getIOSNavGesture = () => {
	return { swipeBack: window.meta.swipeBack, swipeForward: window.meta.swipeForward };
};

const isInWebAppiOS = computed(() => window.navigator.standalone === true);
const isInWebAppChrome = computed(() => window.matchMedia("(display-mode: standalone)").matches);

const setOverscrollBehavior = () => {
	if (isIOS.value && (isInWebAppiOS.value || isInWebAppChrome.value)) {
		body.style.overscrollBehavior = "none";
		html.style.overscrollBehavior = "none";
	} else {
		body.style.overscrollBehavior = "auto";
		html.style.overscrollBehavior = "auto";
	}
};

// setOverscrollBehavior();

const refreshIOSNavGesture = () => {
	window.meta = {
		leftEdge: 0,
		swipeFromLeft: 0,
		swipeBack: false,
		rightEdge: 0,
		swipeFromRight: 0,
		swipeForward: false,
	};
};

export {
	hasTouch,
	isIOS,
	isAndroid,
	isInWebAppiOS,
	isInWebAppChrome,
	isMobileBrowser,
	isDesktop,
	isStandalone,
	detectIOSNavGesture,
	getIOSNavGesture,
	refreshIOSNavGesture,
};
