import { onMounted } from "vue";
import { isMobileBrowser } from "@/composables/usePlatform";

var mobiledomain = "<mobilehub>";
var systemidvar = "<systemID>";
var username = "";
var password = "";
var lang = "EN";
var mode = "real";
let real = 1;
var game = "bal";
// Optional Variables
let lobbyUrl = "";
let logoutUrl = "";
let supportUrl = "";
let depositUrl = "";
var gameType = "";
var playtechWindow = "playtechw";

const loginPlaytechLive = async (result, gameTypeShortCode, gameCode) => {
	username = result.loginid;
	password = result.password;
	gameType = gameTypeShortCode;
	if (gameCode) game = gameCode;
	window.iapiSetClientType("casino");
	window.iapiSetClientPlatform("web");
	window.iapiLogin(username, password, 1, lang.toLowerCase());
};

const calloutLoginPlaytech = (response) => {
	if (response.errorCode) {
		// console.log(response);
		// Login failed
		if (response.errorCode == 48) {
			alert(
				"Login failed, error: " +
					response.errorCode +
					" playerMessage: " +
					response.actions.PlayerActionShowMessage[0].message
			);
		} else {
			alert("Login failed, error: " + response.errorCode + " playerMessage: " + response.playerMessage);
		}
	} else {
		// Login success
		// console.log(response);
		launchPlaytechLiveCasino();
	}
};

const launchPlaytechLiveCasino = () => {
	// Get variables
	let lobbyUrl = "";
	let logoutUrl = "";
	let supportUrl = "";
	let depositUrl = "";
	window.iapiConf.clientUrl_ngm_desktop = "https://cachedownload-am.htsp123.com/ngmdesktop/casinoclient.html";
	window.iapiConf.clientUrl_ngm_mobile = "https://games-am.htsp123.com/casinomobile/casinoclient.html";
	window.iapiConf.clientUrl_live_desk = "https://cachedownload-am.htsp123.com/live/html5/desktop/";
	window.iapiConf.clientUrl_live_mob = "https://cachedownload-am.htsp123.com/live/html5/mobile/";
	if (gameType == "LC") {
		// Live Games
		let client = isMobileBrowser.value ? "live_mob" : "live_desk";
		// console.log("start SetClientParams livecasino");
		window.iapiSetClientParams(
			client,
			"&launch_alias=" +
				game +
				"&language=" +
				lang +
				"&real=" +
				real +
				"&lobby=" +
				lobbyUrl +
				"&logout=" +
				logoutUrl +
				"&deposit=" +
				depositUrl +
				"&support=" +
				supportUrl
		);
		// console.log("start launch livecasino game");
		window.open("", playtechWindow);
		window.iapiLaunchClient(client, null, mode, playtechWindow);
		// console.log("launched livecasino");
	} else {
		let client = isMobileBrowser.value ? "ngm_mobile" : "ngm_desktop";
		// console.log("start SetClientParams slot");
		window.iapiSetClientParams(
			client,
			"&language=" +
				lang +
				"&real=" +
				real +
				"&lobby=" +
				lobbyUrl +
				"&logout=" +
				logoutUrl +
				"&deposit=" +
				depositUrl +
				"&support=" +
				supportUrl
		);
		// console.log("start launch slot game");
		window.open("", playtechWindow);
		window.iapiLaunchClient(client, game, mode, playtechWindow);
		// console.log("launched slot");
	}
	//var url = 'https://hub.tothinkit.com/igaming/?gameId=' + game + '&real=' + real + '&username=' + response.username.username + '&lang=' + lang + '&tempToken=' + response.rootSessionToken.sessionToken;
	//window.open(url, "_blank")
};

let script = document.createElement("script");
script.setAttribute("src", "https://login-am.htsp123.com/jswrapper/hotspin88am/integration.js");
document.head.appendChild(script);
// Set up callback after JS file is loaded
script.onload = () => {
	window.iapiSetCallout("Login", calloutLoginPlaytech);
};

export { loginPlaytechLive, calloutLoginPlaytech, launchPlaytechLiveCasino };
