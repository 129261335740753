import { MutationTree } from "vuex";
import { ModalStateInterface } from "./state";

export enum ModalControlMutations {
  SET_CURRENT_MODAL = "SET_CURRENT_MODAL",
}

export const mutations: MutationTree<ModalStateInterface> = {
  [ModalControlMutations.SET_CURRENT_MODAL](state, payload: string) {
    state.currentModal = payload;
  },
};
