export interface ModalStateInterface {
  currentModal: string;
}

function state(): ModalStateInterface {
  return {
    currentModal: "closed",
  };
}

export default state;
