import { Module } from "vuex";
import { RootState } from "@/store/state";
import state, { CountryInterface } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export const country: Module<CountryInterface, RootState> = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
