import { DepositGimmicksResponse } from "@/types/DepositGimmicks/Response/DepositGimmicksResponse";

export interface DepositGimmickStateInterface {
	depositGimmick: DepositGimmicksResponse;
}

function state(): DepositGimmickStateInterface {
	return {
		depositGimmick: {} as DepositGimmicksResponse,
	};
}

export default state;
