import { RebateGroupStateInterface } from "./state";
import { MutationTree } from "vuex";
import {
    TierSettingGroupByTierResponse,
} from "@/types/Rebate/Response/RebateResponse";

export enum RebateTierMutations {
  SET_REBATE_TIER = "SET_REBATE_TIER",
}

export const mutations: MutationTree<RebateGroupStateInterface> = {
  [RebateTierMutations.SET_REBATE_TIER](
    state,
    payload: TierSettingGroupByTierResponse[]
  ) {
    state.tierGroup = payload;
  },

};
