import httpClient, { ClientId, ClientName } from "./HttpClient";
import { GetEventResponse } from "@/types/Event/Response/GetEventResponse";
import { MemberLoginEventResponse, UpdateClaimedEventResponse } from "@/types/Event/Response/MemberLoginEventResponse";
import { MemberRecheckInResponse } from "@/types/Event/Response/MemberRecheckInResponse";
import { GetMemberEventReportResponse } from "@/types/Event/Response/GetMemberEventReportResponse";
import { AddEventClaimResponse } from "@/types/Event/Response/AddEventClaimResponse";
import { EventClaimedRequest } from "@/types/Event/Request/EventClaimedRequest";
import { UpdateEventClaimedRequest } from "@/types/Event/Request/UpdateEventClaimedRequest";
import { AddMemberRecheckInRequest } from "@/types/Event/Request/AddMemberRecheckInRequest";
import { AddOnBonusResponse } from "@/types/Event/Response/AddOnBonusResponse";
import { RecheckinResultResponse } from "@/types/Event/Response/RecheckinResultResponse";
import { encrypt } from "@/composables/useEncryption";

const getEvent = (): Promise<GetEventResponse[]> => {
	const endPoint = `/api/Event`;
	const params = {
		tenantId: process.env.VUE_APP_IdentityServer_TenantId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getMemberLoginEvent = (memberId: string): Promise<MemberLoginEventResponse> => {
	const endPoint = `/api/Event/LoginEvent/${memberId}`;
	const params = {
		tenantId: process.env.VUE_APP_IdentityServer_TenantId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const addEventClaimed = (
	memberId: string,
	eventId: number,
	eventRewardIds: number[],
	ticketId?: number
): Promise<AddEventClaimResponse> => {
	const endPoint = `/api/EventClaim`;
	const request: EventClaimedRequest = {
		hash: encrypt({
			eventId: eventId,
			eventRewardIds: eventRewardIds,
			ticketId: ticketId,
			memberGUID: memberId,
		}),
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const updateEventClaimed = (eventClaimedIdsStatus: { [key: number]: number }): Promise<UpdateClaimedEventResponse> => {
	const endPoint = `/api/EventClaim`;
	const request: UpdateEventClaimedRequest = {
		hash: encrypt({
			eventClaimedIdsStatus: eventClaimedIdsStatus,
		}),
	};

	return httpClient.put(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const getMemberRecheckIn = (eventId: number, memberId: string): Promise<MemberRecheckInResponse[]> => {
	const endPoint = `/api/EventClaim/GetMemberRecheckIn`;
	const params = {
		eventId: eventId.toString(),
		memberGUID: memberId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const addMemberRecheckIn = (
	memberId: string,
	eventId: number,
	eventRewardId: number[]
): Promise<RecheckinResultResponse> => {
	const endPoint = `/api/EventClaim/MemberRecheckIn`;
	const request: AddMemberRecheckInRequest = {
		memberGUID: memberId,
		eventId: eventId,
		eventRewardIds: eventRewardId,
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const getMemberEventReport = (from: Date, to: Date, memberId: string): Promise<GetMemberEventReportResponse[]> => {
	const endPoint = `/api/EventClaim/MemberId/${memberId}`;
	const params = {
		from: from,
		to: to,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getMemberAddOnBonus = (memberGUID: string, bonusId: number): Promise<AddOnBonusResponse> => {
	const endPoint = `/api/EventClaim/GetMemberAddOnBonus`;
	const params = {
		memberGUID: memberGUID,
		bonusId: bonusId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export default {
	getEvent,
	getMemberLoginEvent,
	addEventClaimed,
	updateEventClaimed,
	getMemberRecheckIn,
	addMemberRecheckIn,
	getMemberEventReport,
	getMemberAddOnBonus,
};
