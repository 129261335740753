import httpClient, { ClientId } from "./HttpClient";
import { BetSummaryResponse } from "@/types/Report/Response/ReportResponse";
import { BetHistoryResponse } from "@/types/Report/Response/ReportResponse";

const getBetSummary = (
	loginId: string,
	from: string,
	to: string,
	category: string | null
): Promise<BetSummaryResponse[]> => {
	const endPoint = `/api/Bet/Summaries`;
	const params = {
		DateFrom: from,
		DateTo: to,
		Brand: ClientId,
		Category: category,
		LoginId: loginId,
		Frontend: true,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getBetHistory = (
	loginId: string,
	from: string,
	to: string,
	category: string | null,
	vendor: string
): Promise<BetHistoryResponse[]> => {
	const endPoint = `/api/Bet`;
	const params = {
		DateFrom: from,
		DateTo: to,
		Brand: ClientId,
		Vendor: vendor,
		Category: category,
		LoginId: loginId,
		Frontend: true,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export default {
	getBetSummary,
	getBetHistory,
};
