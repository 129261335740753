import { ActionTree } from "vuex";
import { getDepositGimmick } from "@/network/DepositGimmicksService/DepositGimmicks";
import { DepositGimmickStateInterface } from "./state";
import { RootState } from "@/store/state";
import { DepositGimmickMutations } from "./mutations";

export const actions: ActionTree<DepositGimmickStateInterface, RootState> = {
	async fetchDepositGimmicks(context) {
		const res = await getDepositGimmick();
		const data = res?.length > 0 ? res[0] : {};
		console.log(data);
		context.commit(DepositGimmickMutations.SET_DEPOSIT_GIMMICKS, data);
	},
};
