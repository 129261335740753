import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { GamesStateInterface } from "./state";
import { GamesListResponse, Games } from "@/types/Games/Response/GamesListResponse";
import { GamePopularityResponse } from "@/types/Games/Response/GamePopularityResponse";

export const getters: GetterTree<GamesStateInterface, RootState> = {
	getGameList(state): Games[] {
		return state.gameLists.games;
		// .games.filter(
		// 	(x) => (x.isActive == true && x.supportWalletType == 0) || x.supportWalletType == 2
		// );
	},
	getGamePopularityList(state): GamePopularityResponse[] {
		return state.gamePopularity;
	},
};
