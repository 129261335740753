import { LanguageResponse } from "@/types/Language/Response/LanguageResponse";

export interface LanguageStateInterface {
    languageList: LanguageResponse[];
  }
  
  function state(): LanguageStateInterface {
    return {
        languageList: [],
    };
  }
  
  export default state;