import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { BrandStateInterface } from "./state";
import { ContactResponse } from "@/types/Brand/Response/ContactResponse";

export const getters: GetterTree<BrandStateInterface, RootState> = {
	getBrand(state): ContactResponse[] {
		return state.contact.filter((item) => item.status);
	},
};
