import { MutationTree } from "vuex";
import { ProgressTrackerInterface, TaskQueue } from "./state";

export enum ProgressTrackerMutations {
	SET_TASK_QUEUE = "SET_FETCH_QUEUE",
	SET_APP_LOADING = "SET_APP_LOADING",
}

export const mutations: MutationTree<ProgressTrackerInterface> = {
	[ProgressTrackerMutations.SET_TASK_QUEUE](state, payload: TaskQueue) {
		state.taskQueue = payload;
	},
	[ProgressTrackerMutations.SET_APP_LOADING](state, payload: boolean) {
		state.appLoading = payload;
	},
};
