import { ActionTree } from "vuex";
import memberService from "@/network/MemberService/Member";
import { MemberStateInterface } from "./state";
import { RootState } from "@/store/state";
import { MemberMutations } from "./mutations";
import CryptoJS from "crypto-js";
import { isFeatureEnabled } from "@/constant";

export const actions: ActionTree<MemberStateInterface, RootState> = {
	async fetchMember(context) {
		// set loading true
		const currentMember = context.rootGetters["member/getMember"];
		context.commit(MemberMutations.SET_MEMBER, { ...currentMember, loading: true });

		// fetch member
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const member = await memberService.getMember(loginId);

		// set loading false, set force autotransfer
		context.commit(MemberMutations.SET_MEMBER, {
			...member,
			loading: false,
			autoTransfer: isFeatureEnabled["FORCE_AUTO_TRANSFER"] || member.autoTransfer,
		});
	},
	async resetMember(context) {
		context.commit(MemberMutations.SET_MEMBER, {});
	},
	async fetchMemberBank(context) {
		if (!context.state.memberBank == undefined) {
			if (Object.keys(context.state.memberBank).length !== 0) {
				return;
			}
		}
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await memberService.getMemberBank(loginId).then((data) => {
			if (!(data == undefined || Object.keys(data).length === 0)) {
				context.commit(MemberMutations.SET_IS_MEMBERBANK_EXIST, {
					isMemberBankExist: true,
				});
			}
			context.commit(MemberMutations.SET_ON_LOAD_MEMBERBANK, {
				onLoadMemberBankStatus: true,
			});
			return data;
		});
		context.commit(MemberMutations.SET_MEMBER_BANK, res);
	},
	async resetMemberBank(context) {
		context.commit(MemberMutations.SET_ON_LOAD_MEMBERBANK, { onLoadMemberBankStatus: false });
		context.commit(MemberMutations.SET_IS_MEMBERBANK_EXIST, { isMemberBankExist: false });
		context.commit(MemberMutations.SET_MEMBER_BANK, []);
	},
	async addMemberBank(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await memberService.addMemberBank(
			loginId,
			payload.bankId,
			payload.accountHolder,
			payload.accountNumber
		);
		return res;
	},
	async updateMemberBank(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await memberService.updateMemberBank(
			loginId,
			payload.bankId,
			payload.accountHolder,
			payload.accountNumber
		);
		return res;
	},
	async changePassword(_, payload) {
		await memberService.changePassword(payload.phoneNo, payload.currentPassword, payload.newPassword);
	},
	async resetPassword(_, payload) {
		return await memberService.resetPassword(payload.phoneNo, payload.token, payload.newPassword);
	},
	async updateDOB(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		await memberService.updateMemberDOB(loginId, payload.dob);
	},
	async forgotPassword(context, payload) {
		const token = await context.dispatch("identityServer/fetchRegisterToken", null, { root: true });
		return await memberService.forgotPassword(payload.phoneNo, token, payload.byPassSms, payload.tokenOTP);
	},
	async getMemberCount(context) {
		const token = await context.dispatch("identityServer/fetchRegisterToken", null, { root: true });
		const res = await memberService.getMemberCount(token);
		context.commit(MemberMutations.SET_MEMBER_COUNT, res);
	},
	async fetchAspUserClient(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await memberService.fetchAspUserClient(memberId, payload.clientId);
		context.commit(MemberMutations.SET_USER_CLIENT, res);
	},
	async exchangeToken(context) {
		const ipAddress = await context.dispatch("ipAddress/fetchIPAddress", null, {
			root: true,
		});
		await memberService
			.exchangeToken(ipAddress)
			.then((data) => {
				const expirationDate = new Date().getTime() + data.expiresIn * 1000;
				const cipher = CryptoJS.AES.encrypt(
					data.accessToken,
					CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Encryption_Key),
					{
						iv: CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Initial_Vector),
						mode: CryptoJS.mode.CBC,
					}
				);
				//Insert value into local storage
				localStorage.setItem("token", cipher.toString());
				localStorage.setItem("tokenExpiration", expirationDate.toString());
			})
			.catch(() => {
				localStorage.removeItem("token");
			});
	},

	async updateAutoTransfer(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		return await memberService.updateAutoTransfer(loginId, payload.autoTransfer);
	},
	async fetchMemberReferral(context) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await memberService.getMemberReferral(loginId);
		context.commit(MemberMutations.SET_MEMBER_REFERRAL, res);
	},
	async fetchMemberReferralList(context) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const referralType = 1;
		const res = await memberService.getMemberReferralList(memberId, referralType);
		context.commit(MemberMutations.SET_MEMBER_REFERRAL_LIST, res);
	},
};
