export default {
	faq: [
		{
			q: "Win 'n Go+ 是什么？",
			a: "Win 'n Go+ 是一种特殊的钱包功能，可以让您的余额每天获得利息，为您提供了余额增长的机会。",
		},
		{
			q: "Win 'n Go+ 是如何运作的？",
			a: "您在 Win 'n Go+ 钱包中的余额将会每天获得利息。您可以从这个钱包中存入或取出资金，但每笔交易都有特定的条件和程序。",
		},
		{
			q: "我如何通过 Win 'n Go+ 赚取每日利息？",
			a: "您在 Win 'n Go+ 钱包中的余额会自动赚取每日利息。 利息将根据您的 Win 'n Go+ 钱包中的余额每天存入您的 Win 'n Go+ 帐户。 每日合并余额计算利息的截止时间为00:00。",
		},
		{
			q: "什么是锁定钱包？为什么当我从 Win 'n Go+ 提现时我的余额会转移到那里？",
			a: "当您兑现时，您的 Win 'n Go+ 钱包中所赚取的利息将全数转移到锁定钱包。 要从锁定钱包中提取资金，您将需要达到所提取利息 5 倍的流水额。 这是确保负责任博彩的安全措施。",
		},
		{
			q: "Win 'n Go+ 钱包中的金额有上限吗？",
			a: "您的 Win 'n Go+ 钱包中可持有的最大金额将受有效利息条款和条件的约束。 一旦达到此限额，每日利息将不再存入您的帐户。",
		},
		{
			q: "Win 'n Go+ 钱包的最低转账金额是多少？",
			a: "Win 'n Go+ 钱包的最低转账金额为 {minAmount}。",
		},
		{
			q: "如果我的 Win 'n Go+ 钱包遇到任何问题，我该怎么办？",
			a: "如果您遇到任何问题或对 Win 'n Go+ 钱包有疑问，请通过线上聊天向我们的客服团队寻求帮助。 他们很乐意帮助解决您可能遇到的任何问题。",
		},
		{
			q: "如何把钱转入我的 Win 'n Go+ 钱包？",
			a: "要把钱转入您的 Win 'n Go+ 钱包，只需点击我们平台主屏幕上的“Win 'n Go+”部分即可。 然后，选择“转入”，输入所需金额，然后按照步骤提交表格。",
		},
		{
			q: "我可以直接从 Win 'n Go+ 钱包提取资金吗？",
			a: "如果您的 Win 'n Go+ 尚未获得任何利息, 您可以把本金直接转入您的主钱包，然后再进行提款。\n如果您的 Win 'n Go+ 已赚取利息，您在转出资金前必须确保您的账户已经打完所有的流水额。 您的本金仍将直接转入您的主钱包。 但是，您所赚取的利息将转入锁定钱包，并赋予特定的流水要求。",
		},
	],
};
