import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { livaDataStateInterface } from "./state";
import { liveTransactionResponse } from "@/types/LiveData/Response/liveDataResponse";
import { serverISOStringToDateGMT } from "@/composables/useCountry";

export const getters: GetterTree<livaDataStateInterface, RootState> = {
	getProgressiveJackpotValue(state): number {
		return state.progressiveJackpotValue;
	},
	getLiveDepositList(state): liveTransactionResponse[] {
		return state.liveDepositList.map((transaction) => {
			return {
				...transaction,
				type: "deposit",
				date: serverISOStringToDateGMT(transaction.createdDate),
			};
		});
	},
	getLiveWithdrawalList(state): liveTransactionResponse[] {
		return state.liveWithdrawalList.map((transaction) => {
			return {
				...transaction,
				type: "withdrawal",
				date: serverISOStringToDateGMT(transaction.createdDate),
			};
		});
	},
};
