import axios from "axios";
import CryptoJS from "crypto-js";

const httpClient = axios.create({
	baseURL: process.env.VUE_APP_EventAPI_URI,
	headers: {
		Accept: "application/json",
		"X-Forwarded-Host": location.host,
		"Content-Type": "application/json",
		// anything you want to add to the headers
	},
});

httpClient.interceptors.request.use(function (config) {
	const token = localStorage.getItem("token");
	let tokenString = "";
	if (token) {
		const cipher = CryptoJS.AES.decrypt(token, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Encryption_Key), {
			iv: CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Initial_Vector),
			mode: CryptoJS.mode.CBC,
		});
		tokenString = CryptoJS.enc.Utf8.stringify(cipher).toString();
	}

	config.headers.Authorization = `Bearer ${tokenString}`;
	return config;
});

export const ClientId = `${process.env.VUE_APP_IdentityServer_ClientId}`;
export const ClientName = `${process.env.VUE_APP_IdentityServer_ClientName}`;

export default httpClient;
