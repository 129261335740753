// import { createStore } from "vuex";
// import GlobalActions from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { announcement } from "./AnnouncementModule/index";
import { bank } from "./BankModule/index";
import { banner } from "./BannerModule/index";
import { bonus } from "./BonusModule/index";
import { brand } from "./BrandModule/index";
import { downloadLinks } from "./DownloadLinksModule/index";
import { events } from "./EventModule/index";
import { games } from "./GamesModule/index";
import { globalSetting } from "./GlobalSettingModule/index";
import { identityServer } from "./IdentityServerModule/index";
import { inbox } from "./InboxModule/index";
import { ipAddress } from "./IpAddressModule/index";
import { language } from "./LanguageModule/index";
import { memberAccount } from "./MemberAccountModule/index";
import { member } from "./MemberModule/index";
import { modal } from "./ModalModule/index";
import { memberGroup } from "./MemberGroupModule/index";
import { tierRebate } from "./RebateModule/index";
import { miniGames } from "./MiniGamesModule/index";
import { payment } from "./PaymentModule/index";
import { paymentChannel } from "./PaymentChannelModule/index";
import { register } from "./RegisterModule/index";
import { tenant } from "./TenantModule/index";
import { vendors } from "./VendorsModule/index";
import { winLosses } from "./WinLossesModule/index";
import { progressTracker } from "./ProgressTrackerModule/index";
import { window } from "./WindowModule/index";
import { country } from "./CountryModule/index";
import { report } from "./ReportModule/index";
import { depositGimmick } from "./DepositGimmicksModule/index";
import { liveData } from "./LiveDataModule/index";

import Vuex, { StoreOptions } from "vuex";
import state, { RootState } from "@/store/state";

const store: StoreOptions<RootState> = {
	state: state,
	mutations,
	getters,
	modules: {
		announcement,
		bank,
		banner,
		bonus,
		brand,
		downloadLinks,
		events,
		games,
		globalSetting,
		inbox,
		identityServer,
		ipAddress,
		language,
		memberAccount,
		member,
		miniGames,
		modal,
		memberGroup,
		tierRebate,
		payment,
		paymentChannel,
		register,
		tenant,
		vendors,
		winLosses,
		progressTracker,
		window,
		country,
		report,
		depositGimmick,
		liveData,
	},
};

export default new Vuex.Store<RootState>(store);
