import { GamesStateInterface } from "./state";
import { MutationTree } from "vuex";
import { GamesListResponse } from "@/types/Games/Response/GamesListResponse";
import { GamePopularityResponse } from "@/types/Games/Response/GamePopularityResponse";

export enum GamesMutations {
	SET_GAMES_LIST = "SET_GAMES_LIST",
	SET_GAMES_POPULARITY = "SET_GAMES_POPULARITY",
}

export const mutations: MutationTree<GamesStateInterface> = {
	[GamesMutations.SET_GAMES_LIST](state, payload: GamesListResponse) {
		state.gameLists = payload;
	},
	[GamesMutations.SET_GAMES_POPULARITY](state, payload: GamePopularityResponse[]) {
		state.gamePopularity = payload;
	},
};
