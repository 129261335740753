export interface IdentityServerStateInterface {
	isLogin: boolean;
	didAutoLogout: boolean;
	loginId: string;
	memberId: string;
	onRefresh: boolean;
	onSubmitLogin: boolean;
	isInterchange: boolean;
	sid: string;
	disconnectSignalr: boolean;
}

function state(): IdentityServerStateInterface {
	return {
		isLogin: false,
		didAutoLogout: false,
		loginId: "",
		memberId: "",
		onRefresh: true,
		onSubmitLogin: false,
		isInterchange: false,
		sid: null,
		disconnectSignalr: false,
	};
}

export default state;
