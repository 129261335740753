import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { GlobalSettingStateInterface } from "./state";
import { GlobalSettingResponse } from "@/types/GlobalSetting/Response/GlobalSettingResponse";

export const getters: GetterTree<GlobalSettingStateInterface, RootState> = {
	getGlobalSetting(state): GlobalSettingResponse[] {
		return state.globalSetting;
	},
	getCurrentTime(state): string {
		return state.currentTime;
	},
	getLink(state): (linkName?: string) => string | null {
		return (linkName?: string) => {
			const linkItem = state.getLink.find((item) => item.linkName === linkName);
			return linkItem ? linkItem.element : null;
		};
	},
};
