export interface CountryInterface {
	timezone: number;
}

function state(): CountryInterface {
	return {
		timezone: 0,
	};
}

export default state;
