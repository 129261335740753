import { ActionTree } from "vuex";
import { RootState } from "@/store/state";
import { ModalControlMutations } from "./mutations";
import { ModalStateInterface } from "./state";

export const actions: ActionTree<ModalStateInterface, RootState> = {
  openModal(context, payload) {
    if (typeof payload == "string") {
      context.commit(ModalControlMutations.SET_CURRENT_MODAL, payload);
    }
  },

  closeModal(context) {
    const currentModal = context.rootGetters["modal/getCurrentModal"];
    if (currentModal != "closed")
      context.commit(ModalControlMutations.SET_CURRENT_MODAL, "closing");
  },

  closeModalDone(context) {
    context.commit(ModalControlMutations.SET_CURRENT_MODAL, "closed");
  },
};
