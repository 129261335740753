import { ActionTree } from "vuex";
import { getTimezone } from "@/network/CountryService/Country";
import { CountryInterface } from "./state";
import { RootState } from "@/store/state";
import { CountryMutations } from "./mutations";

export const actions: ActionTree<CountryInterface, RootState> = {
	async fetchTimezone(context) {
		const res = await getTimezone();
		context.commit(CountryMutations.SET_TIMEZONE, res);
		return res;
	},
};
