import CryptoJS from "crypto-js";
const ClientSecret = process.env.VUE_APP_IdentityServer_ClientSecret;

const encrypt = (params) => {
	const hashInSHA256 = CryptoJS.SHA256(ClientSecret);
	const base64Key = CryptoJS.enc.Base64.stringify(hashInSHA256);
	const key = CryptoJS.MD5(base64Key).toString();

	const hash = CryptoJS.AES.encrypt(JSON.stringify(params), CryptoJS.enc.Utf8.parse(key), {
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7,
	});

	return hash.toString();
};

export { encrypt };
