import axios from "axios";
import CryptoJS from 'crypto-js';
import UAParser from 'ua-parser-js';

const parser = new UAParser();
const userAgent = parser.getResult();

// This is for user agent details
const getFormattedUserAgentInfo = (info) => {
  const parts = [];

  if (info.device.type === "mobile") {
    if (info.device.vendor) {
      parts.push(info.device.vendor);
    }
    if (info.device.model) {
      parts.push(info.device.model);
    }
  } else {
    parts.push('');
  }

  if (info.os.name) {
    parts.push(info.os.name);
  }
  if (info.os.version) {
    parts.push(info.os.version);
  }

  return parts.join('|');
};

const userAgentOs = userAgent.os.name; // User agent os 
const userAgentDetail = getFormattedUserAgentInfo(userAgent);

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_IdentityServer_URI,
  headers: {
    Accept: "application/json",
    "X-Forwarded-Host": location.host,
    "X-UserAgent": userAgentOs,
    "X-UserAgentDetail": userAgentDetail,
    withCredentials: true,
    // anything you want to add to the headers
  },
});

httpClient.interceptors.request.use(function (config){

  const token = localStorage.getItem('token');
  let tokenString = "";
  if(token){
    const cipher = CryptoJS.AES.decrypt(token, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Encryption_Key), {
      iv: CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Initial_Vector),
      mode: CryptoJS.mode.CBC
    })
    tokenString = CryptoJS.enc.Utf8.stringify(cipher).toString();
  }

  config.headers.Authorization = `Bearer ${tokenString}`;
  config.headers["X-UserAgent"] = userAgentOs; 
  config.headers["X-UserAgentDetail"] = userAgentDetail;
  // console.log(config.headers);
  return config;
})

export const ClientId = `${process.env.VUE_APP_IdentityServer_ClientId}`;
export const ClientSecret = `${process.env.VUE_APP_IdentityServer_ClientSecret}`;

export default httpClient;
