import { LanguageStateInterface } from "./state";
import { LanguageResponse } from "@/types/Language/Response/LanguageResponse";
import { MutationTree } from "vuex";

export enum LanguageMutations {
  SET_LANGUAGE_LIST = "SET_LANGUAGE_LIST",
}

export const mutations: MutationTree<LanguageStateInterface> = {
  [LanguageMutations.SET_LANGUAGE_LIST](state, payload: LanguageResponse[]) {
    state.languageList= payload;
  },
};
