import httpClient, { ClientId } from "./HttpClient";
import { GetUserDepositAmountResponse } from "@/types/Payment/Response/GetUserDepositAmountResponse";
import { GetUserDepositAmountRequest } from "@/types/Payment/Request/GetUserDepositAmountRequest";

const getUserTurnoverAmount = (
	userIds: string[],
	dateFrom: Date,
	dateTo: Date
): Promise<GetUserDepositAmountResponse[]> => {
	const endPoint = `/api/Bets/GetUserTurnOverAmount`;
	const request: GetUserDepositAmountRequest = {
		userIds: userIds,
		dateFrom: dateFrom,
		dateTo: dateTo,
	};
	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

export default { getUserTurnoverAmount };
