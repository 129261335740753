import { MiniGamesListResponse } from "@/types/MiniGames/Response/MiniGamesListResponse";
import { MiniGamesUserResponse } from "@/types/MiniGames/Response/MiniGamesUserResponse";

export interface MiniGamesStateInterface {
	miniGamesList: MiniGamesListResponse[];
	miniGameUrl: string;
	miniGameUser: MiniGamesUserResponse;
}

function state(): MiniGamesStateInterface {
	return {
		miniGamesList: [],
		miniGameUrl: "",
		miniGameUser: undefined,
	};
}

export default state;
