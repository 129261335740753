import { CountryInterface } from "./state";
import { MutationTree } from "vuex";

export enum CountryMutations {
	SET_TIMEZONE = "SET_TIMEZONE",
}

export const mutations: MutationTree<CountryInterface> = {
	[CountryMutations.SET_TIMEZONE](state, payload: number) {
		state.timezone = payload;
	},
};
