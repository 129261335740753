<template>
	<b-modal
		id="transfersummary-modal"
		class="defaultModal"
		centered
		:title="t('common.transferSummary')"
		v-model="showModal"
		@show="initModal"
		@hidden="endModal"
	>
		<div class="tableContainer">
			<table class="table">
				<thead class="tableHeader">
					<tr>
						<th>{{ t("common.provider") }}</th>
						<th>{{ t("statement.amount") }}</th>
						<th>{{ t("statement.status") }}</th>
					</tr>
				</thead>
				<tbody class="tableBody">
					<tr v-for="(transaction, index) in summaryList" :key="index">
						<td>{{ transaction.vendorDisplayName }}</td>
						<td>
							<span>{{ transaction.ticketAmt }}</span>
							<font-awesome-icon
								:icon="['fas', 'arrow-right']"
								class="maxpayoutArrow"
								v-if="transaction.maxPayout > 0"
							/>
							<span class="amountMaxpayout" v-if="transaction.maxPayout > 0">
								{{ transaction.maxPayout }}
							</span>
						</td>
						<td>
							<span
								class="statusMsg"
								:class="{ success: !transaction.errorMsg, error: transaction.errorMsg }"
								>{{ transaction.errorMsg || t("common.success") }}</span
							>
							<span class="maxpayout" v-if="transaction.maxPayout > 0">
								, {{ t("common.maxPayout", { maxPayout: transaction.maxPayout }) }}
							</span>
						</td>
					</tr>

					<tr v-for="(transaction, index) in turnoverList" :key="index">
						<td>{{ transaction.vendorDisplayName }}</td>
						<td>{{ 0 }}</td>
						<td>
							<span class="statusMsg error">
								{{ transaction.turnover ? t("common.turnover exist") : "" }}
								{{ transaction.winover ? t("common.winover exist") : "" }}
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<template #footer>
			<button type="button" class="mainButton" @click="endModal">
				{{ t("common.close") }}
			</button>
		</template>
	</b-modal>
</template>

<script setup>
import { ref, computed, watch, nextTick, defineExpose } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { isLogin } from "@/composables/useAuth";

const { t, locale } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();

// ================= Transfer Summary Start =================

const transferToMainResult = computed(() => store.getters["payment/getTransferToMainResult"]);
const summaryList = computed(() => transferToMainResult.value?.vendorTransferSuccessLists);
const turnoverList = computed(() => transferToMainResult.value?.transferAllBalToMainResponses);

// ================= Transfer Summary End =================

// ================= Modal Control Start =================

const MODAL_NAME = "transferSummary";
const showModal = ref(false);

const setModalParam = () => {
	router.replace({ query: { ...route.query, modal: MODAL_NAME } });
};

const unsetModalParam = () => {
	const { modal, ...params } = route.query;
	router.replace({ query: params });
};

watch(route, async (newRoute, oldRoute) => {
	if (newRoute.query?.modal != MODAL_NAME) {
		showModal.value = false;
		return;
	}
	await nextTick();
	if (isLogin.value) showModal.value = true;
	else unsetModalParam();
});

const openModal = () => (showModal.value = true);

const hideModal = () => {
	unsetModalParam();
	showModal.value = false;
};

const initModal = async () => {
	setModalParam();
	return;
};

const endModal = () => {
	hideModal();
	return;
};

// ================= Modal Control End =================

defineExpose({ openModal });
</script>

<style scoped lang="sass">
table
	width: 100%
	border-collapse: collapse
	text-align: left
	th, td
		padding: 0.5rem 0.8rem
		color: var(--text-regular)
	th
		background: var(--background-primary-dark)
		border: none
	td
		border-bottom: 1px solid var(--border-regular)
		.statusMsg
			&.success
				color: var(--success)
			&.error
				color: var(--danger)
		.maxpayoutArrow
			margin: 0 0.5rem
</style>
