import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { MiniGamesStateInterface } from "./state";
import { MiniGamesListResponse } from "@/types/MiniGames/Response/MiniGamesListResponse";
import { MiniGamesUserResponse } from "@/types/MiniGames/Response/MiniGamesUserResponse";

export const getters: GetterTree<MiniGamesStateInterface, RootState> = {
	getMiniGamesList(state): MiniGamesListResponse[] {
		return state.miniGamesList;
	},
	getMiniGameUrl(state): string {
		return state.miniGameUrl;
	},
	getMiniGameUser(state): MiniGamesUserResponse {
		return state.miniGameUser;
	},
};
