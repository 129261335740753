import httpClient, { ClientId } from "./HttpClient";
import { MiniGamesListResponse } from "@/types/MiniGames/Response/MiniGamesListResponse";
import { MiniGamesUserResponse } from "@/types/MiniGames/Response/MiniGamesUserResponse";

const getMiniGames = (loginId: string, userId: string): Promise<MiniGamesUserResponse[]> => {
	const endPoint = `/api/User`;
	const params = {
		LoginId: loginId,
		UserId: userId,
		ClientId: ClientId,
	};
	const data = httpClient.get(endPoint, { params }).then((response) => response.data);
	return data;
};

const getMiniGamesList = (): Promise<MiniGamesListResponse[]> => {
	const endPoint = `/api/SpinningWheel/SpinningWheels`;
	const params = {
		clientId: ClientId,
	};
	const data = httpClient.get(endPoint, { params }).then((response) => response.data);
	return data;
};

const getMiniGamesUrl = (lang: string, minigameId?: number): Promise<string> => {
	const endPoint = `/api/Url/Internal`;
	const params = {
		clientId: ClientId,
		lang: lang,
		minigameId: minigameId,
	};
	const data = httpClient.get(endPoint, { params }).then((response) => response.data);
	return data;
};

export { getMiniGamesList, getMiniGamesUrl, getMiniGames };
