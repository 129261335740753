<template>
	<div class="termsContainer container-sm">
		<div class="termsDescription">
			{{ t("terms.Description") }}
		</div>

		<div class="termsContent">
			<div class="termsItem cursorPointer" v-for="terms in termsList" :key="terms.id" @click="toggle(terms.id)">
				<div class="termsName" :class="terms.id == currentId ? 'open' : ''">
					{{ terms.id + ". " + terms.title }}

					<font-awesome-icon
						:icon="['fas', 'chevron-down']"
						class="arrow"
						:class="{ active: terms.id == currentId }"
					/>
				</div>

				<div class="termsDetails" v-if="terms.id == currentId">
					{{ terms.description }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

const { t, locale } = useI18n();
const router = useRouter();
const route = useRoute();

const termsList = computed(() => [
	{
		id: 1,
		title: t("terms.Definition"),
		description: t("terms.Description1"),
	},
	{
		id: 2,
		title: t("terms.Agree"),
		description: t("terms.Description2"),
	},
	{
		id: 3,
		title: t("terms.Modify"),
		description: t("terms.Description3"),
	},
	{
		id: 4,
		title: t("terms.GamingInformationandIntellectualProperty"),
		description: t("terms.Description4"),
	},
	{
		id: 5,
		title: t("terms.ConditionsofUse"),
		description: t("terms.Description5"),
	},
	{
		id: 6,
		title: t("terms.RegistertoOpenaBettingAccountandMembership"),
		description: t("terms.Description6"),
	},
	{
		id: 7,
		title: t("terms.BettingandAcceptingBets"),
		description: t("terms.Description7"),
	},
	{
		id: 8,
		title: t("terms.GamingSoftwareUseRights"),
		description: t("terms.Description8"),
	},
	{
		id: 9,
		title: t("terms.TransactionSettlement"),
		description: t("terms.Description9"),
	},
	{
		id: 10,
		title: t("terms.ReceiveWinnings"),
		description: t("terms.Description10"),
	},
	{
		id: 11,
		title: t("terms.PromotionsandBonuses"),
		description: t("terms.Description11"),
	},
	{
		id: 12,
		title: t("terms.Compensation"),
		description: t("terms.Description12"),
	},
	{
		id: 13,
		title: t("terms.Disclaimerandspecialnotes"),
		description: t("terms.Description13"),
	},
	{
		id: 14,
		title: t("terms.TerminationAccountClosureandSuspensionofGamingServices"),
		description: t("terms.Description14"),
	},
	{
		id: 15,
		title: t("terms.LinkstoExternalWebsites"),
		description: t("terms.Description15"),
	},
	{
		id: 16,
		title: t("terms.LinksFramestoBettingSites"),
		description: t("terms.Description16"),
	},
	{
		id: 17,
		title: t("terms.IncreaseorInterrupttheTypeofBetting"),
		description: t("terms.Description17"),
	},
	{
		id: 18,
		title: t("terms.ViolationofthisRegulation"),
		description: t("terms.Description18"),
	},
	{
		id: 19,
		title: t("terms.Priority"),
		description: t("terms.Description19"),
	},
	{
		id: 20,
		title: t("terms.ForceMajeure"),
		description: t("terms.Description20"),
	},
	{
		id: 21,
		title: t("terms.Abstain"),
		description: t("terms.Description21"),
	},
	{
		id: 22,
		title: t("terms.Severability"),
		description: t("terms.Description22"),
	},
	{
		id: 23,
		title: t("terms.ApplicableLawandJurisdiction"),
		description: t("terms.Description23"),
	},
	{
		id: 24,
		title: t("terms.ResponsibleBetting"),
		description: t("terms.Description24"),
	},
	{
		id: 25,
		title: t("terms.PrizePoolSeedGoldStatement"),
		description: t("terms.Description25"),
	},
]);

const currentId = ref(-1);
const openItem = (id) => (currentId.value = id);
const closeItem = (id) => (currentId.value = -1);

const toggle = (id) => {
	if (currentId.value == id) closeItem(id);
	else openItem(id);
};
</script>

<style scoped lang="sass">
.termsContainer
  margin-block: 1rem
.termsDescription
  margin-block: 1rem
.termsContent
  white-space: pre-line
  margin-block: 2rem
.termsItem
  padding: 0.8rem
  border-bottom: 1px solid var(--border-regular)
  &:first-child
    border-top: 1px solid var(--border-regular)
.termsName
  font-weight: var(--font-bold)
  display: flex
  justify-content: space-between
.arrow
  transition: 0.2s
  &.active
    transform: rotate(-180deg)
.termsDetails
  margin-top: 0.5rem
</style>
