import { Module } from "vuex";
import { RootState } from "@/store/state";
import state, { PaymentChannelStateInterface } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutation";
import { actions } from "./actions";

export const paymentChannel: Module<PaymentChannelStateInterface, RootState> = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
