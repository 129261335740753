import { GetEventResponse } from "@/types/Event/Response/GetEventResponse";
import { MemberLoginEventResponse } from "@/types/Event/Response/MemberLoginEventResponse";
import { MemberRecheckInResponse } from "@/types/Event/Response/MemberRecheckInResponse";
import { GetMemberEventReportResponse } from "@/types/Event/Response/GetMemberEventReportResponse";
import { AddOnBonusResponse } from "@/types/Event/Response/AddOnBonusResponse";
import { GetUserDepositAmountResponse } from "@/types/Payment/Response/GetUserDepositAmountResponse";

export interface EventStateInterface {
	eventList: GetEventResponse[];
	memberLoginEvent: MemberLoginEventResponse;
	memberRecheckInList: MemberRecheckInResponse[];
	memberEventReport: GetMemberEventReportResponse[];
	eventRewardByDay: [];
	eventClaimedByDay: [];
	memberAddOnBonus: AddOnBonusResponse;
	memberRecheckInByDay: [];
	userDepositAmount: GetUserDepositAmountResponse[];
	checkInEventStart: boolean;
}

function state(): EventStateInterface {
	return {
		eventList: [],
		memberLoginEvent: {} as MemberLoginEventResponse,
		memberRecheckInList: [],
		memberEventReport: [],
		eventRewardByDay: [],
		eventClaimedByDay: [],
		memberAddOnBonus: {} as AddOnBonusResponse,
		memberRecheckInByDay: [],
		userDepositAmount: {} as GetUserDepositAmountResponse[],
		checkInEventStart: false,
	};
}

export default state;
