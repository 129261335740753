import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { ReportStateInterface } from "./state";
import { BetSummaryResponse, BetHistoryResponse } from "@/types/Report/Response/ReportResponse";
import { serverISOStringToDateGMT, clientTimeNow } from "@/composables/useCountry";
// const timezone = new Date().getTimezoneOffset();

export const getters: GetterTree<ReportStateInterface, RootState> = {
	getBetHistory(state): BetHistoryResponse[] {
		state.betHistory.map((BetHistoryItem) => {
			const createDate = serverISOStringToDateGMT(BetHistoryItem.transactionDate);
			BetHistoryItem.transactionDate = createDate;
			return BetHistoryItem;
		});
		state.betHistory.sort((a, b) => {
			return new Date(b.transactionDate).getTime() - new Date(a.transactionDate).getTime();
		});
		return state.betHistory;
	},

	getBetSummary(state): BetSummaryResponse[] {
		return state.betSummary;
	},
};
