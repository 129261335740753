import { ActionTree } from "vuex";
import inboxService from "@/network/InboxService/Inbox";
import { InboxStateInterface } from "./state";
import { RootState } from "@/store/state";
import { InboxMutations } from "./mutations";
import CryptoJS from "crypto-js";

export const actions: ActionTree<InboxStateInterface, RootState> = {
	async fetchMemberInbox(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const inboxTypeId = payload.inboxTypeId != undefined ? payload.inboxTypeId : null;
		let res = await inboxService.getFrontEndMemberInbox(
			loginId,
			inboxTypeId,
			payload.languageId,
			payload.read,
			payload.pin
		);
		res = res.filter((x) => x.isDeleted == false);
		context.commit(InboxMutations.SET_MEMBER_INBOX_LIST, res);

		const unreadMsg = res.filter((x) => x.read == false).length;
		context.commit(InboxMutations.SET_INBOX_UNREAD_COUNT, unreadMsg);
	},
	async clearMemberInbox(context) {
		context.commit(InboxMutations.SET_INBOX_UNREAD_COUNT, 0);
	},
	async updateMemberInboxList(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		return await inboxService.updateMemberInboxList(loginId, payload.memberInboxList);
	},
	async updateMemberInboxById(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		return await inboxService.updateMemberInbox(
			payload.id,
			loginId,
			payload.inboxSettingId,
			payload.read,
			payload.pin,
			payload.isDeleted
		);
	},
	async fetchMemberInboxById(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await inboxService.getFrontEndMemberInboxById(payload.id, payload.languageId);
		context.commit(InboxMutations.SET_MEMBER_INBOX, res);
	},
	async fetchKeyword(context) {
		const res = await inboxService.getKeywordList();
		context.commit(InboxMutations.SET_KEYWORD, res);
	},
	async updateInboxUnreadCount(context, payload) {
		context.commit(InboxMutations.SET_INBOX_UNREAD_COUNT, payload.unreadCount);
	},
};
