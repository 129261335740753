import { ref, computed, watch, onMounted } from "vue";
import { isLogin } from "@/composables/useAuth";
import store from "@/store/index";
import router from "@/router";
import vueI18n from "@/resource/index";
import { languageCodeToId } from "@/composables/useCulture";
import { isFeatureEnabled } from "@/constant";

const { t, locale } = vueI18n.global;
// ================= Fetch Start =================

const queueFetch = () => store.dispatch("progressTracker/queueTask");
const completeFetch = () => store.dispatch("progressTracker/completeTask");

const fetchWinover = async () => {
	queueFetch();
	const loginId = store.getters["identityServer/getLoginId"];
	await store
		.dispatch("memberAccount/fetchMemberWinover", {
			loginId: loginId,
			isFromDB: true,
		})
		.finally(() => completeFetch());
};

const fetchTurnover = async () => {
	queueFetch();
	const loginId = store.getters["identityServer/getLoginId"];
	await store
		.dispatch("memberAccount/fetchMemberTurnover", {
			loginId: loginId,
			isFromDB: true,
		})
		.finally(() => completeFetch());
};
const fetchMemberActiveVendorList = async () => {
	queueFetch();
	await store.dispatch("vendors/fetchMemberActiveVendorList").finally(() => completeFetch());
};

const fetchMember = async () => {
	queueFetch();
	await store.dispatch("member/fetchMember").finally(() => completeFetch());
};

const fetchMemberWallet = async () => {
	queueFetch();
	await store.dispatch("memberAccount/fetchMemberWallet").finally(() => completeFetch());
};

const fetchMemberTier = async () => {
	queueFetch();
	await store.dispatch("memberGroup/fetchMemberCurrentTier").finally(() => completeFetch());
};

const fetchMemberGroupList = async () => {
	queueFetch();
	await store.dispatch("memberGroup/fetchMemberGroupList").finally(() => completeFetch());
};

const fetchVendorCategory = async () => {
	queueFetch();
	await store.dispatch("vendors/fetchCategoryList").finally(() => completeFetch());
};

const fetchVendors = async () => {
	queueFetch();
	await store.dispatch("vendors/fetchVendor").finally(() => completeFetch());
};

const tryLogin = async () => {
	queueFetch();
	await store.dispatch("identityServer/tryLogin").finally(() => completeFetch());
};

const fetchIPAddress = async () => {
	queueFetch();
	await store.dispatch("ipAddress/fetchIPAddress").finally(() => completeFetch());
};

const fetchMemberBonusList = async () => {
	queueFetch();
	await store.dispatch("bonus/fetchMemberBonusList").finally(() => completeFetch());
};

const fetchMemberPreBonusList = async () => {
	queueFetch();
	await store
		.dispatch("bonus/fetchMemberPreBonusList", { langId: languageCodeToId[locale.value], keepStore: true })
		.finally(() => completeFetch());
};

const fetchBonusList = async () => {
	queueFetch();
	await store.dispatch("bonus/fetchBonusList").finally(() => completeFetch());
};

const fetchRebate = async () => {
	queueFetch();
	await store.dispatch("tierRebate/fetchRebateTier").finally(() => completeFetch());
};

const fetchActiveBonusApplied = async () => {
	queueFetch();
	await store.dispatch("bonus/fetchActiveBonusApplied").finally(() => completeFetch());
};

const fetchActiveBonusAppliedWithBonusVendor = async () => {
	queueFetch();
	await store.dispatch("bonus/fetchActiveBonusAppliedWithBonusVendor").finally(() => completeFetch());
};

const fetchGames = async () => {
	queueFetch();
	await store.dispatch("games/fetchGameList", { id: null }).finally(() => completeFetch());
};

const fetchBanner = async () => {
	queueFetch();
	await store.dispatch("banner/fetchBanner").finally(() => completeFetch());
};

const fetchMemberBank = async () => {
	queueFetch();
	await store.dispatch("member/fetchMemberBank").finally(() => completeFetch());
};

const fetchBrand = async () => {
	queueFetch();
	await store.dispatch("brand/fetchBrand").finally(() => completeFetch());
};

const fetchDepositBank = async () => {
	queueFetch();
	await store.dispatch("bank/fetchDepositBank").finally(() => completeFetch());
};

const fetchGlobalSetting = async () => {
	queueFetch();
	await store.dispatch("globalSetting/fetchGlobalSetting", { remark: "" }).finally(() => completeFetch());
};

const fetchPromotion = async () => {
	queueFetch();
	await store.dispatch("payment/fetchMemberBonusBirthday").finally(() => completeFetch());
};

const fetchCheckInList = async () => {
	queueFetch();
	await store.dispatch("events/fetchMemberLoginEvent").finally(() => completeFetch());
};

const fetchMemberReferral = async () => {
	queueFetch();
	await store.dispatch("member/fetchMemberReferral").finally(() => completeFetch());
};

const fetchMemberReferralList = async () => {
	queueFetch();
	await store.dispatch("member/fetchMemberReferralList").finally(() => completeFetch());
};

const fetchInboxList = async () => {
	queueFetch();
	await store
		.dispatch("inbox/fetchMemberInbox", { languageId: languageCodeToId[locale.value] })
		.finally(() => completeFetch());
};

const fetchMiniGames = async () => {
	queueFetch();
	await store.dispatch("miniGames/fetchMiniGames").finally(() => completeFetch());
};

const fetchMiniGamesUser = async () => {
	queueFetch();
	await store.dispatch("miniGames/fetchMiniGamesUser").finally(() => completeFetch());
};

const fetchMemberAddOnBonus = async () => {
	queueFetch();
	await store.dispatch("events/fetchMemberAddOnBonus").finally(() => completeFetch());
};

const fetchTimezone = async () => {
	queueFetch();
	await store.dispatch("country/fetchTimezone").finally(() => completeFetch());
};

const fetchCurrentTime = () => {
	store.dispatch("globalSetting/fetchCurrentTime");
};

const fetchGamesPopularity = async () => {
	queueFetch();
	await store.dispatch("games/fetchGamesPopularity").finally(() => completeFetch());
};

const fetchDownloadLink = async () => {
	queueFetch();
	await store.dispatch("downloadLinks/fetchDownloadLinks").finally(() => completeFetch());
};

const fetchDepositGimmicks = async () => {
	queueFetch();
	await store.dispatch("depositGimmick/fetchDepositGimmicks").finally(() => completeFetch());
};

const fetchLinkSetting = async () => {
	queueFetch();
	await store.dispatch("globalSetting/fetchLinkSetting").finally(() => completeFetch());
};

// ================= Fetch End =================

const fetchBeforeLoginList = async () => {
	fetchIPAddress();
	fetchTimezone();
	fetchVendors();
	fetchBonusList();
	fetchGames();
	fetchBanner();
	fetchBrand();
	fetchVendorCategory();
	if (isFeatureEnabled["MINIGAME"]) fetchMiniGames();
	fetchRebate();
	fetchCurrentTime();
	fetchGamesPopularity();
	fetchDownloadLink();
	// fetchLinkSetting();
};

const fetchAfterLoginList = async () => {
	// Can run in parallel
	fetchWinover();
	fetchTurnover();
	fetchGlobalSetting();
	fetchDepositBank();
	fetchMemberReferral();
	//fetchPromotion();
	fetchMemberBank();
	fetchMemberGroupList();

	// Has dependency on member data
	await fetchMember();
	fetchMemberTier();
	fetchMemberActiveVendorList();
	fetchCheckInList();
	// fetchMemberBonusList();
	// fetchMemberPreBonusList();
	if (isFeatureEnabled["REFERRAL"]) fetchMemberReferralList();
	// fetchActiveBonusApplied();
	fetchActiveBonusAppliedWithBonusVendor();
	fetchInboxList();
	if (isFeatureEnabled["MINIGAME"]) fetchMiniGames();
	if (isFeatureEnabled["MINIGAME"]) fetchMiniGamesUser();
	fetchMemberAddOnBonus();
	fetchPromotion();
	fetchDepositGimmicks();
};

const fetchAPIList = () => {
	if (isLogin) fetchAfterLoginList();
	else fetchBeforeLoginList();
};
export { fetchBeforeLoginList, fetchAfterLoginList, fetchAPIList };
