import { BonusResponse } from "@/types/Bonus/Response/BonusResponse";
import { BonusClaimedResponse } from "@/types/Bonus/Response/BonusClaimedResponse";
import { UniqueCodeResponse } from "@/types/Bonus/Response/UniqueCodeResponse";
import { ActiveBonusAppliedResponse } from "@/types/Bonus/Response/ActiveBonusAppliedResponse";
import { MemberGroupBonusResponse } from "@/types/Bonus/Response/MemberGroupBonusResponse";
import {
	ActiveBonusAppliedWithBonusVendorResponse,
	MemberForfeitedBonusByVendorResponse,
} from "@/types/Bonus/Response/ActiveBonusAppliedWithBonusVendorResponse";

export interface BonusStateInterface {
	bonusAllList: BonusResponse[];
	bonusList: BonusResponse[];
	memberBonus: BonusResponse[];
	uniqueCode: UniqueCodeResponse[];
	bonusClaimed: BonusClaimedResponse[];
	activeBonusApplied: ActiveBonusAppliedResponse[];
	memberGroupBonusResponse: MemberGroupBonusResponse[];
	activeBonusAppliedWithBonusVendor: ActiveBonusAppliedWithBonusVendorResponse[];
	memberForfeitedBonusByVendor: MemberForfeitedBonusByVendorResponse[];
}

function state(): BonusStateInterface {
	return {
		bonusAllList: [],
		bonusList: [],
		memberBonus: [],
		uniqueCode: [],
		bonusClaimed: [],
		activeBonusApplied: [],
		memberGroupBonusResponse: [],
		activeBonusAppliedWithBonusVendor: [],
		memberForfeitedBonusByVendor: [],
	};
}

export default state;
