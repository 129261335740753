import httpClient, { ClientId } from "./HttpClient";
import { VendorResponse } from "@/types/Vendor/Response/VendorResponse";
import { VendorAccountResponse } from "@/types/Vendor/Response/VendorAccountResponse";
import { CategoryResponse } from "@/types/Vendor/Response/CategoryResponse";
import {
	TransferableVendorResponse,
	TransferableVendorDetailResponse,
} from "@/types/Vendor/Response/TransferableVendorResponse";

const getVendor = (vendorName = null, clientId?: string): Promise<VendorResponse[]> => {
	const currentClientId = clientId == undefined ? ClientId : clientId;
	const endPoint = `/api/Vendors`;
	const params = {
		clientId: currentClientId,
		name: vendorName,
		active: true,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getVendorAccount = (vendorId: number): Promise<VendorAccountResponse> => {
	const endPoint = `/api/VendorAccounts/${vendorId}`;
	const params = {
		clientId: ClientId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getCategoryList = (): Promise<CategoryResponse[]> => {
	const endPoint = `/api/Vendors/Categories/${ClientId}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getMemberActiveVendorList = (memberId: string): Promise<VendorResponse[]> => {
	const endPoint = `/api/Vendors/GetMemberActiveVendorList`;
	const params = {
		clientId: ClientId,
		memberId: memberId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getTransferableVendor = (
	loginId: string,
	vendorName: string,
	bonusId?: number
): Promise<TransferableVendorResponse[]> => {
	const endPoint = `/api/Vendors/GetTransferableVendor`;
	const params = {
		loginId: loginId,
		clientId: ClientId,
		vendorName: vendorName,
		bonusId: bonusId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getTransferableVendorDetail = (loginId: string, vendorName: string): Promise<TransferableVendorResponse[]> => {
	const endPoint = `/api/Vendors/TransferableVendorDetail`;
	const params = {
		loginId: loginId,
		clientId: ClientId,
		vendorName: vendorName,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export default {
	getVendor,
	getVendorAccount,
	getCategoryList,
	getMemberActiveVendorList,
	getTransferableVendor,
	getTransferableVendorDetail,
};
