import {
    TierSettingGroupByTierResponse,
  } from "@/types/Rebate/Response/RebateResponse";
  
  export interface RebateGroupStateInterface {
    tierGroup: TierSettingGroupByTierResponse[];
  }
  
  function state(): RebateGroupStateInterface {
    return {
        tierGroup: [] as TierSettingGroupByTierResponse[],
    };
  }
  
  export default state;