import { RootState } from "./state";
import { MutationTree } from "vuex";

export enum RootMutations {
	SET_VENDOR_REGISTER_STATUS = "SET_VENDOR_REGISTER_STATUS",
}

export const mutations: MutationTree<RootState> = {
	[RootMutations.SET_VENDOR_REGISTER_STATUS](state, payload: boolean) {
		state.isVendorRegistration = payload;
	},
};
