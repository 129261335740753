import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { InboxStateInterface } from "./state";
import { MemberInboxResponse } from "@/types/Inbox/Response/MemberInboxResponse";
import { KeywordResponse } from "@/types/Inbox/Response/KeywordResponse";
import { member } from "../MemberModule";
import { serverISOStringToDateGMT } from "@/composables/useCountry";

export const getters: GetterTree<InboxStateInterface, RootState> = {
	getMemberInbox(state): MemberInboxResponse {
		return state.memberInbox;
	},
	getKeyword(state): KeywordResponse {
		return state.keyword;
	},
	getMemberInboxList(state): MemberInboxResponse[] {
		return state.memberInboxList
			.map((msg) => {
				return { ...msg, created: serverISOStringToDateGMT(msg.created) };
			})
			.sort(function (a, b) {
				return Number(b.pin) - Number(a.pin);
			});
		// return memberInboxList;
	},
	getInboxUnreadCount(state): number {
		return state.inboxUnreadCount;
	},
};
