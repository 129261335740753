export default {
	faq: [
		{
			q: "What is Win 'n Go+?",
			a: "Win 'n Go+ is a special wallet feature that allows your balance to earn daily interest. It offers you the opportunity to grow your balance over time.",
		},
		{
			q: "How does Win 'n Go+ work?",
			a: "Your balance in the Win 'n Go+ wallet earns daily interest. You can cash in or out from this wallet, but there are specific conditions and procedures for each transaction.",
		},
		{
			q: "How do I earn daily interest with Win 'n Go+?",
			a: "Your balance in the Win 'n Go+ wallet automatically earns daily interest. The interest will be credited to your Win 'n Go+ account daily based on the balance in your Win 'n Go+ wallet. The daily cut-off time for consolidating balances for interest calculation is 00:00.",
		},
		{
			q: "What is the Locked Wallet, and why is my balance transferred there when I cash out from Win 'n Go+?",
			a: "The Locked Wallet is where your interest earned from the Win 'n Go+ wallet is transferred when you cash out. To withdraw funds from the Locked Wallet, you'll be subjected to 5 times turnover of the interest withdrawn. This is a security measure to ensure responsible gaming.",
		},
		{
			q: "Is there a maximum amount I can have in my Win 'n Go+ wallet?",
			a: "The maximum amount that can be held in your Win 'n Go+ wallet will be subjected to the active interest terms and conditions. Once this limit is reached, no more daily interest will be credited to your account.",
		},
		{
			q: "What is the minimum transfer amount for cashing in to Win 'n Go+ wallet?",
			a: "The minimum transfer amount for cashing in to the Win 'n Go+ wallet is {minAmount}.",
		},
		{
			q: "What should I do if I encounter any issues with my Win 'n Go+ wallet?",
			a: "If you encounter any issues or have questions about your Win 'n Go+ wallet, please seek assistance from our customer service team via live chat. They'll be happy to help resolve any concerns you may have.",
		},
		{
			q: "How do I cash in to my Win 'n Go+ wallet?",
			a: "To cash in to your Win 'n Go+ wallet, simply tap on the 'Win 'n Go+' section from the home screen of our platform. Then, select 'Cash in', enter the desired amount, and follow the steps to submit the form.",
		},
		{
			q: "Can I withdraw funds directly from my Win 'n Go+ wallet?",
			a: "Yes, if you haven't earned any interest yet. In this case, your principal amount will be transferred directly into your Main Wallet and you will be able to make withdrawal.\nNo, if your Win 'n Go+ has interest. You'll need to ensure there's no turnover remaining in your account. Your principal amount will still be transferred directly into your Main Wallet. However, your interest earned will be credited to your Locked Wallet and subject to a turnover requirement.",
		},
	],
};
