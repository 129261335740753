<template>
	<header>
		<div class="homeHeaderContainer">
			<!-- ================= DESKTOP ================= -->
			<div v-if="!isMobile" class="headerContent container">
				<!-- Header Left Panel Start -->
				<UnifiedLink :isRouteLink="home.isRouteLink" :link="home.link">
					<div class="headerContentLeft">
						<img :src="logoUrl" class="headerLogo" />
					</div>
				</UnifiedLink>
				<!-- Header left Panel End -->

				<!-- Header Right Panel Start -->
				<div class="headerContentRight">
					<div class="rowTop">
						<BalanceDropdown v-if="isLogin" />

						<template v-else>
							<UnifiedLink
								:isRouteLink="myaccount.isRouteLink"
								:link="myaccount.link"
								:isModal="myaccount.isModal"
								:modalName="myaccount.modalName"
							>
								<button type="button" class="headerLinkButton unstyledButton">
									<font-awesome-icon :icon="myaccount.icon" class="icon" />
									{{ t("route.myaccount") }}
								</button>
							</UnifiedLink>

							<UnifiedLink
								:isRouteLink="forgotPassword.isRouteLink"
								:isModal="forgotPassword.isModal"
								:modalName="forgotPassword.modalName"
								:guestOnly="forgotPassword.guestOnly"
							>
								<button type="button" class="headerLinkButton unstyledButton">
									<font-awesome-icon :icon="forgotPassword.icon" class="icon" />
									{{ t("route.forgotpassword") }}
								</button>
							</UnifiedLink>
						</template>

						<div class="timeContent">
							{{ currentTime }}
						</div>
					</div>

					<div class="rowBottom">
						<template v-if="isLogin">
							<!-- Links -->
							<template v-for="nav in headerList" :key="nav.title">
								<UnifiedLink
									:isRouteLink="nav.isRouteLink"
									:link="nav.link"
									:isModal="nav.isModal"
									:modalName="nav.modalName"
									:isEvent="nav.isEvent"
									:event="nav.event"
									:validateLogin="nav.validateLogin"
									:validateBank="nav.validateBank"
									@click="handleEvent(nav.event)"
								>
									<button class="headerLinkButton unstyledButton">
										<font-awesome-icon :icon="nav.icon" class="icon" />
										{{ t(`route.${nav.title}`) }}
									</button>
								</UnifiedLink>
							</template>
							<!-- Links -->

							<!-- Inbox -->
							<UnifiedLink
								:isRouteLink="inbox.isRouteLink"
								:link="inbox.link"
								:validateLogin="inbox.validateLogin"
								:validateBank="inbox.validateBank"
							>
								<div class="inboxContainer">
									<div class="notificationBadge" v-if="getInboxUnreadCount > 0">
										{{ getInboxUnreadCount }}
									</div>
									<font-awesome-icon class="inboxIcon" :icon="inbox.icon" />
								</div>
							</UnifiedLink>
							<!-- Inbox -->

							<!-- Deposit -->
							<UnifiedLink
								:isRouteLink="deposit.isRouteLink"
								:link="deposit.link"
								:validateLogin="deposit.validateLogin"
								:validateBank="deposit.validateBank"
							>
								<button class="mainButton depositButton">{{ t("route.deposit") }}</button>
							</UnifiedLink>
							<!-- Deposit -->

							<!-- Logout -->
							<button class="secondaryButton logoutButton" @click="logOut">
								{{ t("route.logout") }}
							</button>
							<!-- Logout -->
						</template>

						<HeaderLogin v-else />

						<LanguageDropdown />
					</div>
				</div>
			</div>
			<!-- ================= DESKTOP ================= -->

			<!-- ================= MOBILE ================= -->
			<div v-if="isMobile" class="headerContent">
				<div v-if="route.path == '/'" class="headerItem">
					<UnifiedLink
						:isRouteLink="inbox.isRouteLink"
						:link="inbox.link"
						:validateLogin="inbox.validateLogin"
						:validateBank="inbox.validateBank"
					>
						<div class="inboxContainer">
							<div class="notificationBadge" v-if="getInboxUnreadCount > 0">
								{{ getInboxUnreadCount }}
							</div>
							<font-awesome-icon class="inboxIcon" :icon="inbox.icon" />
						</div>
					</UnifiedLink>
				</div>

				<div v-else class="headerItem" @click="router.back">
					<font-awesome-icon class="headerItemIcon backIcon" :icon="['fas', 'chevron-left']" />
				</div>

				<UnifiedLink :isRouteLink="home.isRouteLink" :link="home.link">
					<div class="headerContentLeft">
						<img :src="logoUrl" class="headerLogo" />
					</div>
				</UnifiedLink>

				<LanguageDropdown />
			</div>
			<!-- ================= MOBILE ================= -->
		</div>

		<NavBar v-if="!isMobile" />
	</header>
</template>

<script setup>
import { ref, computed, watch, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useIsMobile } from "@/composables/useMobile";
import { isLogin } from "@/composables/useAuth";
import { routeData } from "@/constant";
import { authField, useLogout } from "@/composables/useAuth";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";
import BalanceModal from "@/components/modal/BalanceModal.vue";
import LanguageDropdown from "@/components/layout/header/LanguageDropdown.vue";
import NavBar from "@/components/layout/header/NavBar.vue";
import HeaderLogin from "@/components/layout/header/HeaderLogin.vue";
import BalanceDropdown from "@/components/layout/header/BalanceDropdown.vue";

const { t, locale } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();
const isMobile = useIsMobile();

const imgUrlDomain = process.env.VUE_APP_Media_Domain;
const logo = "/assets/images/NavBar/BankBank2.png";
const profile = "/assets/images/Shared/default-profile-icon.jpg";
const logoUrl = imgUrlDomain + logo;
const profileUrl = imgUrlDomain + profile;

const contactPage = routeData.find((x) => x.title == "contact");
const myaccount = routeData.find((x) => x.title == "myaccount");
const forgotPassword = routeData.find((x) => x.title == "forgotpassword");
const inbox = routeData.find((x) => x.title == "inbox");
const deposit = routeData.find((x) => x.title == "deposit");
const logout = routeData.find((x) => x.title == "logout");
const search = routeData.find((item) => item.title == "search");
const home = routeData.find((item) => item.title == "home");

const isHomeRoute = computed(() => {
	return route.path == "/";
});

// const filterCheckInList = computed(() => {});

// ================= Header Start =================

const headerNameList = [
	// "checkin",
	"myaccount",
	"statement",
	"withdrawal",
	"transfer",
];
const headerList = computed(() =>
	headerNameList.reduce((accumulator, headerName) => {
		accumulator.push(routeData.find((route) => route.title == headerName));
		return accumulator;
	}, [])
);

const handleEvent = (event) => {
	if (event == "livechat") openLivechat();
	return;
};

const getInboxUnreadCount = computed(() => store.getters["inbox/getInboxUnreadCount"]);

const contactList = computed(() => {
	return store.getters["brand/getBrand"];
});

const openLivechat = () => {
	const livechatItem = contactList.value.find((x) => x?.brandName.toLowerCase() == "livechat");
	if (livechatItem != undefined) {
		window.open(livechatItem?.redirectURL, "_blank");
	} else {
		router.push(contactPage.link);
	}
};

const logOut = () => {
	useLogout();
};

// ================= Header End =================

// ================= Balance Start =================

const balance = computed(() => {
	return store.getters["member/getMember"]?.balance || 0;
});

// ================= Balance Start =================

// ================= Time Start =================

const currentTime = computed(() => store.getters["globalSetting/getCurrentTime"]);

// ================= Time End =================

// ================= Check In Start =================

const isLoginEventStart = computed(() => isLogin.value && store.getters["events/getCheckInEventStart"]);

// ================= Check In End =================
</script>

<style scoped lang="sass">
header
	box-shadow: var(--box-shadow)
	position: sticky
	top: 0
	left: 0
	width: 100%
	z-index: 30
.homeHeaderContainer
	background: var(--gradient-primary-reversed)
.headerContent
	display: flex
	justify-content: space-between
	align-items: center
	padding: 1rem
	@media (max-width: 767px)
		padding-block: 0
		border-block: 3px solid var(--border-accent)

// .headerContentLeft
.headerLogo
	height: 3.5rem
	cursor: pointer
	margin: 0.5rem 0
	@media (max-width: 767px)
		height: 2.5rem

.headerContentRight
	display: flex
	align-items: end
	flex-direction: column
	gap: 0.75rem
	.rowTop
		display: flex
		align-items: center
		gap: 0.75rem
	.rowBottom
		display: flex
		align-items: center
		gap: 0.75rem
.headerLinkButton
	font-size: 0.9em
	font-weight: var(--font-medium)
	&:hover
		color: var(--color-accent)
	.icon
		margin-inline: 0.15rem

.router-link-active
	color: var(--color-accent)

.timeContent
	font-size: 0.9em
	width: 12rem
	text-wrap: nowrap
.inboxContainer
	position: relative
	margin-inline: 0.5rem 0.75rem
	&:hover
		.inboxIcon
			color: var(--color-accent)
	@media (max-width: 767px)
		margin: 0
.notificationBadge
	position: absolute
	top: -0.25rem
	right: -0.5rem
	line-height: 1em
	font-size: 0.75em
	font-weight: var(--font-bold)
	text-align: center
	color: var(--text-inverted)
	background: var(--danger)
	min-width: 1.5em
	border-radius: 999rem
	// outline: 1.5px solid var(--background-primary)
	padding: 0.25em 0.25em
.inboxIcon
	font-size: 1.75em
.depositButton, .logoutButton
	height: 2.5rem
	min-width: 7rem
	width: fit-content
	display: flex
	align-items: center
	justify-content: center
	padding: 0
.headerItem
	display: flex
	align-items: center
	width: 2rem
	cursor: pointer
.headerItemIcon
	font-size: 1.8em
	&.backIcon
		font-size: 1.5em
</style>
