import { GamesListResponse } from "@/types/Games/Response/GamesListResponse";
import { GamePopularityResponse } from "@/types/Games/Response/GamePopularityResponse";

export interface GamesStateInterface {
	gameLists: GamesListResponse;
	gamePopularity: GamePopularityResponse[];
}

function state(): GamesStateInterface {
	return {
		gameLists: { count: null, games: [] },
		gamePopularity: [],
	};
}

export default state;
