import { ActionTree } from "vuex";
import vendorService from "@/network/VendorService/Vendor";
import { VendorStateInterface } from "./state";
import { RootState } from "@/store/state";
import { VendorMutations } from "./mutations";

export const actions: ActionTree<VendorStateInterface, RootState> = {
	async fetchVendor(context) {
		if (Object.keys(context.state.vendor).length !== 0) {
			return;
		}
		const vendorList = await vendorService.getVendor();
		if (vendorList.length > 0) {
			const filterVendorList = vendorList.filter((x) => x.active);
			context.commit(VendorMutations.SET_VENDOR_INFO, filterVendorList);
		}
	},
	async fetchVendorWithName(context, payload) {
		return await vendorService.getVendor(payload.vendorName);
	},
	async fetchMemberActiveVendorList(context) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const memberActiveVendorList = await vendorService.getMemberActiveVendorList(memberId);
		context.commit(VendorMutations.SET_ACTIVE_VENDOR, memberActiveVendorList);
	},
	setVendorBalanceLoading(context, payload) {
		let memberActiveVendorList = context.rootGetters["vendors/getActiveMemberVendor"]();
		memberActiveVendorList = memberActiveVendorList.map((vendor) => {
			if (vendor.vendorAccountId == payload.vendorAccountId) {
				vendor.loading = payload.status;
			}
			return vendor;
		});
		context.commit(VendorMutations.SET_ACTIVE_VENDOR, memberActiveVendorList);
	},
	hotUpdateVendorBalance(context, payload) {
		let memberActiveVendorList = context.rootGetters["vendors/getActiveMemberVendor"]();
		memberActiveVendorList = memberActiveVendorList.map((vendor) => {
			if (vendor.id == payload.id) {
				vendor.memberBalance = payload.balance;
			}
			return vendor;
		});
		context.commit(VendorMutations.SET_ACTIVE_VENDOR, memberActiveVendorList);
	},
	async fetchExternalVendor(context, payload) {
		const vendorList = await vendorService.getVendor(payload);
		return vendorList;
	},
	async fetchVendorAccount(context, payload) {
		if (Object.keys(context.state.vendorAccount).length !== 0) {
			return context.state.vendorAccount;
		}
		const vendor = context.state.vendor;
		const vendorAccount = await vendorService.getVendorAccount(payload.id);
		context.commit(VendorMutations.SET_VENDOR_ACCOUNT, vendorAccount);

		return vendorAccount;
	},
	async fetchCategoryList(context) {
		if (Object.keys(context.state.categoryList).length !== 0) {
			return;
		}
		const categoryList = await vendorService.getCategoryList();
		context.commit(VendorMutations.SET_CATEGORY_LIST, categoryList);
	},
	async fetchTransferableVendor(context, payload) {
		context.commit(VendorMutations.SET_TRANSFERABLE_VENDOR, []);
		const loginId = context.rootGetters["identityServer/getLoginId"];
		let transferableVendor = [];
		if (payload?.vendorName)
			transferableVendor = await vendorService.getTransferableVendor(
				loginId,
				payload.vendorName,
				payload.bonusId
			);
		context.commit(VendorMutations.SET_TRANSFERABLE_VENDOR, transferableVendor);
		return transferableVendor;
	},
	async fetchTransferableVendorDetail(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const transferableVendorDetail = await vendorService.getTransferableVendorDetail(loginId, payload.vendorName);
		context.commit(VendorMutations.SET_TRANSFERABLE_VENDOR_DETAIL, transferableVendorDetail);
		return transferableVendorDetail;
	},
};
