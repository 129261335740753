import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { ProgressTrackerInterface, TaskQueue } from "./state";

export const getters: GetterTree<ProgressTrackerInterface, RootState> = {
	getTaskQueue(state): TaskQueue {
		return state.taskQueue;
	},
	getAppLoading(state): boolean {
		return state.appLoading;
	},
};
