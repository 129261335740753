import { ref, computed, onMounted, onBeforeUnmount, nextTick } from "vue";

const MAX_SIZE = 767;

const useIsMobile = () => {
  const isMobile = ref(window.innerWidth <= MAX_SIZE);

  function onResize() {
    isMobile.value = window.innerWidth <= MAX_SIZE;
  }

  onMounted(async () => {
    await nextTick();
    window.addEventListener("resize", onResize);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("resize", onResize);
  });

  return isMobile;
};

const userAgent = computed(() => {
  return navigator.userAgent || navigator.vendor;
});

const isIphone = computed(() => {
  return userAgent.value.includes("iPhone");
});

export { useIsMobile, userAgent, isIphone };
