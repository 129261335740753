import { MutationTree } from "vuex";
import { WindowInterface } from "./state";

export enum WindowMutations {
	PUSH_NEW_WINDOW = "PUSH_NEW_WINDOW",
}

export const mutations: MutationTree<WindowInterface> = {
	[WindowMutations.PUSH_NEW_WINDOW](state, payload: any) {
		state.windowList.push(payload);
	},
};
