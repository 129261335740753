import httpClient, { ClientId } from "./HttpClient";
import { GlobalSettingResponse } from "@/types/GlobalSetting/Response/GlobalSettingResponse";
import { GetLinkResponse } from "@/types/GlobalSetting/Response/GetLinkResponse";

const getGlobalSetting = (remark: string): Promise<GlobalSettingResponse[]> => {
	const endPoint = `/api/GlobalSetting`;
	const params = {
		remark: remark,
		ClientId: ClientId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getLinkSetting = (): Promise<GetLinkResponse[]> => {
	const endPoint = `/api/Link/${ClientId}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

export { getGlobalSetting, getLinkSetting };
