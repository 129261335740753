import { AutoDepositResponse } from "@/types/PaymentChannel/Response/AutoDepositResponse";

export interface PaymentChannelStateInterface {
	autoDeposit: AutoDepositResponse;
}

function state(): PaymentChannelStateInterface {
	return {
		autoDeposit: {} as AutoDepositResponse,
	};
}
export default state;
