import httpClient, { ClientId } from "./HttpClient";
import {
  MemberTierResponse,
  MemberGroupListResponse,
} from "@/types/MemberGroup/Response/MemberGroupResponse";

const getMemberCurrentTier = (
  memberId: string
): Promise<MemberTierResponse> => {
  const endPoint = `/api/MemberGroup/MemberCurrentTierInfo/${memberId}`;
  return httpClient.get(endPoint).then((response) => response.data);
};

const getMemberGroupList = (): Promise<MemberGroupListResponse> => {
  const endPoint = "/api/MemberGroup";
  const params = {
    tenantId: process.env.VUE_APP_IdentityServer_TenantId,
  };
  return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export default {
  getMemberCurrentTier,
  getMemberGroupList,
};
