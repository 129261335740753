import { ActionTree } from "vuex";
import reportService from "@/network/ReportService/Report";
import { ReportStateInterface } from "./state";
import { RootState } from "@/store/state";
import { ReportMutations } from "./mutations";
import { clientDateStringToServerISOString } from "@/composables/useCountry";

export const actions: ActionTree<ReportStateInterface, RootState> = {
	async fetchBetSummary(context, payload) {
		// const memberId = context.rootGetters["identityServer/getMemberId"];
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await reportService.getBetSummary(
			loginId,
			clientDateStringToServerISOString(payload.from),
			clientDateStringToServerISOString(payload.to, true),
			payload.category
		);
		context.commit(ReportMutations.SET_BET_SUMMARY, res);
	},

	async fetchBetHistory(context, payload) {
		// const memberId = context.rootGetters["identityServer/getMemberId"];
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await reportService.getBetHistory(
			loginId,
			clientDateStringToServerISOString(payload.from),
			clientDateStringToServerISOString(payload.to, true),
			payload.category,
			payload.vendor
		);
		context.commit(ReportMutations.SET_BET_HISTORY, res);
	},
};
