import { ActionTree } from "vuex";
import memberGroupService from "@/network/MemberGroupService/MemberGroup";
import { MemberGroupStateInterface } from "./state";
import { RootState } from "@/store/state";
import { MemberGroupMutations } from "./mutations";
import CryptoJS from "crypto-js";

export const actions: ActionTree<MemberGroupStateInterface, RootState> = {
	async fetchMemberCurrentTier(context) {
		const member = context.rootGetters["member/getMember"];
		const res = await memberGroupService.getMemberCurrentTier(member.userId);
		context.commit(MemberGroupMutations.SET_MEMBER_CURRENT_TIER, res);
	},
	async clearMemberCurrentTier(context) {
		context.commit(MemberGroupMutations.SET_MEMBER_CURRENT_TIER, {});
	},
	async fetchMemberGroupList(context) {
		const res = await memberGroupService.getMemberGroupList();
		context.commit(MemberGroupMutations.SET_MEMBER_GROUP_LIST, res);
	},
};
