import vueI18n from "@/resource/index";
const { t, locale } = vueI18n.global;

interface languages {
	id: number;
	code: string;
}

const languages: languages[] = [
	{
		id: 1,
		code: "en",
	},
	{
		id: 2,
		code: "zh",
	},
	{
		id: 3,
		code: "ms",
	},
];

const languageCodeToId = {
	en: 1,
	zh: 2,
	ms: 3,
};

const languageIdToCode = {
	1: "en",
	2: "zh",
	3: "ms",
};

const changeLanguage = (language) => {
	locale.value = language;
	document.querySelector("html").setAttribute("lang", language);
	localStorage.setItem("locale", language);
};

export { languages, changeLanguage, languageCodeToId, languageIdToCode };
