import { BonusStateInterface } from "./state";
import { BonusResponse } from "@/types/Bonus/Response/BonusResponse";
import { BonusClaimedResponse } from "@/types/Bonus/Response/BonusClaimedResponse";
import { UniqueCodeResponse } from "@/types/Bonus/Response/UniqueCodeResponse";
import { ActiveBonusAppliedResponse } from "@/types/Bonus/Response/ActiveBonusAppliedResponse";
import { MemberGroupBonusResponse } from "@/types/Bonus/Response/MemberGroupBonusResponse";
import {
	ActiveBonusAppliedWithBonusVendorResponse,
	MemberForfeitedBonusByVendorResponse,
} from "@/types/Bonus/Response/ActiveBonusAppliedWithBonusVendorResponse";
import { MutationTree } from "vuex";

export enum BonusMutations {
	SET_BONUS_ALL_LIST = "SET_BONUS_ALL_LIST",
	SET_BONUS_LIST = "SET_BONUS_LIST",
	SET_MEMBER_BONUS = "SET_MEMBER_BONUS",
	SET_MEMBER_PRE_BONUS = "SET_MEMBER_PRE_BONUS",
	SET_UNIQUE_CODE = "SET_UNIQUE_CODE",
	SET_BONUS_CLAIMED = "SET_BONUS_CLAIMED",
	SET_ACTIVE_BONUS_APPLIED = "SET_ACTIVE_BONUS_APPLIED",
	SET_ACTIVE_BONUS_WITH_VENDOR_APPLIED = "SET_ACTIVE_BONUS_WITH_VENDOR_APPLIED",
	SET_MEMBER_FORFEITED_BY_BONUS_VENDOR = "SET_MEMBER_FORFEITED_BY_BONUS_VENDOR",
}

export const mutations: MutationTree<BonusStateInterface> = {
	[BonusMutations.SET_BONUS_ALL_LIST](state, payload: BonusResponse[]) {
		state.bonusAllList = payload;
	},
	[BonusMutations.SET_BONUS_LIST](state, payload: BonusResponse[]) {
		state.bonusList = payload;
	},
	[BonusMutations.SET_MEMBER_BONUS](state, payload: BonusResponse[]) {
		state.memberBonus = payload;
	},
	[BonusMutations.SET_MEMBER_PRE_BONUS](state, payload: MemberGroupBonusResponse[]) {
		state.memberGroupBonusResponse = payload;
	},
	[BonusMutations.SET_UNIQUE_CODE](state, payload: UniqueCodeResponse[]) {
		state.uniqueCode = payload;
	},
	[BonusMutations.SET_BONUS_CLAIMED](state, payload: BonusClaimedResponse[]) {
		state.bonusClaimed = payload;
	},
	[BonusMutations.SET_ACTIVE_BONUS_APPLIED](state, payload: ActiveBonusAppliedResponse[]) {
		state.activeBonusApplied = payload;
	},
	[BonusMutations.SET_ACTIVE_BONUS_WITH_VENDOR_APPLIED](state, payload: ActiveBonusAppliedWithBonusVendorResponse[]) {
		state.activeBonusAppliedWithBonusVendor = payload;
	},
	[BonusMutations.SET_MEMBER_FORFEITED_BY_BONUS_VENDOR](state, payload: MemberForfeitedBonusByVendorResponse[]) {
		state.memberForfeitedBonusByVendor = payload;
	},
};
