export interface WindowInterface {
	windowList: Array<any>;
}

function state(): WindowInterface {
	return {
		windowList: [],
	};
}

export default state;
