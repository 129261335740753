import httpClient, { ClientId, ClientName } from "./HttpClient";
import { GamePopularityResponse } from "@/types/Games/Response/GamePopularityResponse";

const getLaunchGameUrl = (
	vendorId: number,
	memberId: string,
	category: string,
	lang: string,
	gameCode: string,
	isFun: boolean,
	isMobile: boolean
): Promise<string> => {
	const endPoint = `/api/Games/LaunchGame`;
	const params = {
		memberId: memberId,
		vendorId: vendorId,
		category: category,
		lang: lang,
		gameCode: gameCode,
		isFun: isFun.toString(),
		isMobile: isMobile.toString(),
		clientId: ClientId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getGameList = (vendorId: number, gamesCategory: string): Promise<string> => {
	const endPoint = `/api/Games/Allgames`;
	const params = {
		vendorId: vendorId,
		gamesCategory: gamesCategory,
		clientId: ClientId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getGameCategories = (vendorId: number): Promise<string[]> => {
	const endPoint = `/api/Games/${vendorId}/Categories`;
	const params = {
		vendorId: vendorId,
		clientId: ClientId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getGamePopularity = (take: number): Promise<GamePopularityResponse[]> => {
	const endPoint = `/api/Games/GetGamePopularity`;
	const params = {
		take: take,
		clientId: ClientId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export { getLaunchGameUrl, getGameList, getGameCategories, getGamePopularity };
