import httpClient, { ClientId } from "./HttpClient";
import {
    TierSettingGroupByTierResponse
} from "@/types/Rebate/Response/RebateResponse";

const getRebate = (): Promise<TierSettingGroupByTierResponse> => {
    const tenantId = process.env.VUE_APP_IdentityServer_TenantId;
    const endPoint = `/api/Rebate/TierSettingGroupByTier/${ClientId}/${tenantId}`;
    return httpClient.get(endPoint).then((response) => response.data);
};

export default {
    getRebate,
};
