import { ReportStateInterface } from "./state";
import { BetSummaryResponse, BetHistoryResponse } from "@/types/Report/Response/ReportResponse";
import { MutationTree } from "vuex";

export enum ReportMutations {
	SET_BET_SUMMARY = "SET_BET_SUMMARY",
	SET_BET_HISTORY = "SET_BET_HISTORY",
}

export const mutations: MutationTree<ReportStateInterface> = {
	[ReportMutations.SET_BET_SUMMARY](state, payload: BetSummaryResponse[]) {
		state.betSummary = payload;
	},
	[ReportMutations.SET_BET_HISTORY](state, payload: BetHistoryResponse[]) {
		state.betHistory = payload;
	},
};
