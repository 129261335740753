import httpClient, { ClientId } from "./HttpClient";
import { liveTransactionResponse } from "@/types/LiveData/Response/liveDataResponse";

const fetchLiveTransactions = (transactionType = "Deposit"): Promise<liveTransactionResponse[]> => {
	const endPoint = `/api/Payment/GetTransactionLastRecordInfo`;
	const params = {
		ClientId: ClientId,
		TransactionType: transactionType,
		TakeCount: null,
	};
	const data = httpClient.get(endPoint, { params }).then((response) => response.data);
	return data;
};

export { fetchLiveTransactions };
