import httpClient, { ClientId } from "./HttpClient";
import { MemberInboxResponse } from "@/types/Inbox/Response/MemberInboxResponse";
import { MemberInboxListRequest } from "@/types/Inbox/Request/MemberInboxListRequest";
import { MemberInboxRequest } from "@/types/Inbox/Request/MemberInboxRequest";
import { KeywordResponse } from "@/types/Inbox/Response/KeywordResponse";

const getFrontEndMemberInbox = (
	loginId: string,
	inboxTypeId: number,
	languageId: number,
	read: boolean,
	pin: boolean
): Promise<MemberInboxResponse[]> => {
	const endPoint = `/api/MemberInbox/FrontEnd`;
	const params = {
		loginId: loginId,
		inboxTypeId: inboxTypeId,
		languageId: languageId,
		read: read,
		pin: pin,
		tenantId: process.env.VUE_APP_IdentityServer_TenantId,
		isDeleted: false,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const updateMemberInboxList = (loginId: string, memberInboxList: MemberInboxRequest[]): Promise<boolean> => {
	const endPoint = `/api/MemberInbox/UpdateMemberInboxListBasic`;
	const request: MemberInboxListRequest = {
		loginId: loginId,
		tenantId: process.env.VUE_APP_IdentityServer_TenantId,
		updateMemberInboxList: memberInboxList,
	};
	return httpClient.put(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const updateMemberInbox = (
	id: number,
	loginId: string,
	inboxSettingId: number,
	read: boolean,
	pin: boolean,
	isDeleted: boolean
): Promise<boolean> => {
	const endPoint = `/api/MemberInbox`;
	const request: MemberInboxRequest = {
		id: id,
		loginId: loginId,
		inboxSettingId: inboxSettingId,
		read: read,
		pin: pin,
		isDeleted: isDeleted,
		clientId: process.env.VUE_APP_IdentityServer_ClientId,
		tenantId: process.env.VUE_APP_IdentityServer_TenantId,
	};
	return httpClient.put(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const getFrontEndMemberInboxById = (id: number, languageId: number): Promise<MemberInboxResponse> => {
	const endPoint = `/api/MemberInbox/FrontEndById`;
	const params = {
		id: id,
		languageId: languageId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getKeywordList = (): Promise<KeywordResponse[]> => {
	const endPoint = `/api/Keyword`;
	return httpClient.get(endPoint).then((response) => response.data);
};

export default {
	getFrontEndMemberInbox,
	updateMemberInboxList,
	updateMemberInbox,
	getFrontEndMemberInboxById,
	getKeywordList,
};
