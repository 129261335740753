import { GlobalSettingResponse } from "@/types/GlobalSetting/Response/GlobalSettingResponse";
import { GetLinkResponse } from "@/types/GlobalSetting/Response/GetLinkResponse";

export interface GlobalSettingStateInterface {
	globalSetting: GlobalSettingResponse[];
	currentTime: string;
	getLink: GetLinkResponse[];
}

function state(): GlobalSettingStateInterface {
	return {
		globalSetting: [],
		currentTime: "",
		getLink: [],
	};
}

export default state;
