<template>
	<div class="inputOtpContainer">
		<label class="inputLabel"
			><div class="inputLabelText" :class="{ required: required }">
				{{ label }}
			</div>

			<div class="inputContainer">
				<VOtpInput
					ref="otpInput"
					:value="modelValue"
					input-classes="otp-input"
					input-type="number"
					inputmode="numeric"
					separator=""
					:num-inputs="length"
					:required="required"
					@on-change="(value) => emit('update:modelValue', value)"
					@on-complete="(value) => emit('update:modelValue', value)"
				/>
			</div>
		</label>

		<div class="inputError" v-if="errors.length > 0">
			{{ errors[0]?.$message }}
		</div>
	</div>
</template>

<script setup>
import VOtpInput from "@hauzhengyi/vue3-otp-input";
import { ref, watch, defineProps, defineEmits } from "vue";

const emit = defineEmits(["update:modelValue", "on-complete"]);

const props = defineProps({
	modelValue: {
		type: String,
		default: "",
	},
	label: {
		type: String,
		default: null,
	},
	length: {
		type: Number,
		default: 6,
	},
	required: {
		type: Boolean,
		default: false,
	},
	placeholder: {
		type: String,
		default: null,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	errors: {
		type: Array,
		default: () => [],
	},
});

const otpInput = ref(null);

const clearInput = () => {
	otpInput.value?.clearInput();
};

watch(
	() => props.modelValue,
	(newValue) => {
		if (newValue == "") clearInput();
	}
);
</script>

<style lang="sass" scoped>
.inputContainer
	display: flex
	:deep(div)
		width: 100%
		justify-content: space-between
		gap: 0.8rem
:deep(.otp-input)
	color: var(--text-regular)
	background: var(--input-background)
	border: 1px solid var(--input-border-regular)
	border-radius: 0.5rem
	height: 3.5rem
	width: 100%
	font-size: 1.5em
	text-align: center
	&:not(:disabled)
		&:focus, &:focus-visible, &:focus-within
			outline: none
			border-color: var(--input-border-accent) !important
	&:disabled
		color: var(--text-muted)
	&.is-complete
		border-color: var(--input-border-accent) !important
</style>
