import { MemberWinoverResponse } from "@/types/MemberAccount/Response/MemberWinoverResponse";
import { MemberTurnoverResponse } from "@/types/MemberAccount/Response/MemberTurnoverResponse";
import { MemberAccountResponse } from "@/types/MemberAccount/Response/MemberAccountResponse";
import { MemberWalletResponse } from "@/types/MemberAccount/Response/MemberWalletResponse";

export interface MemberAccountStateInterface {
	balance: number;
	memberWinover: MemberWinoverResponse;
	memberTurnover: MemberTurnoverResponse;
	memberAccount: MemberAccountResponse;
	memberWallet: MemberWalletResponse;
	memberWalletUpdateTime: string;
}

function state(): MemberAccountStateInterface {
	return {
		balance: 0,
		memberTurnover: {} as MemberTurnoverResponse,
		memberWinover: {} as MemberWinoverResponse,
		memberAccount: {} as MemberAccountResponse,
		memberWallet: {} as MemberWalletResponse,
		memberWalletUpdateTime: null,
	};
}

export default state;
