import { liveTransactionResponse } from "@/types/LiveData/Response/liveDataResponse";

export interface livaDataStateInterface {
	progressiveJackpotValue: number;
	liveDepositList: liveTransactionResponse[];
	liveWithdrawalList: liveTransactionResponse[];
}

function state(): livaDataStateInterface {
	return {
		progressiveJackpotValue: 0,
		liveDepositList: [],
		liveWithdrawalList: [],
	};
}

export default state;
