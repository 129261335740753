import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { WinLossesStateInterface } from "./state";
import { BetHistoryResponse } from "@/types/WinLosses/Response/BetHistoryResponse";
import { serverISOStringToDateGMT, clientTimeNow } from "@/composables/useCountry";
// const timezone = new Date().getTimezoneOffset();

export const getters: GetterTree<WinLossesStateInterface, RootState> = {
	getBetHistory(state): BetHistoryResponse[] {
		state.betHistory.map((BetHistoryItem) => {
			const createDate = serverISOStringToDateGMT(BetHistoryItem.gameDate);
			BetHistoryItem.gameDate = createDate;
			return BetHistoryItem;
		});
		return state.betHistory;
	},
};
