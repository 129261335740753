<template>
	<div class="inputSelectBankContainer">
		<label class="inputLabel"
			><span v-if="label" class="inputLabelText" :class="{ required: required }">{{ label }}</span>

			<div class="inputContainer">
				<CustomDropdown
					:floatRight="true"
					:disabled="disabled"
					ref="dropdown"
					@keydown.up.prevent="moveFocus(-1)"
					@keydown.down.prevent="moveFocus(1)"
					@keydown.enter.prevent="handleEnter"
					@keydown.esc.prevent="closeDropdown"
					@keydown.tab="closeDropdown"
					@keydown="handleKeypress"
				>
					<template #toggle>
						<button
							type="button"
							class="unstyledButton defaultInput inputSelect"
							:disabled="disabled"
							tabindex="0"
						>
							<!-- SELECTED CONTENT -->
							<div v-if="modelValue?.[nameKey]" class="select">
								<img
									v-if="imageKey && modelValue[imageKey]"
									:src="modelValue[imageKey]"
									class="optionImage"
								/>
								<span class="optionText textOverflowEllipsis">{{ modelValue[nameKey] }}</span>
							</div>
							<!-- SELECTED CONTENT -->

							<!-- PLACEHOLDER -->
							<span v-else class="placeholder">{{ placeholder }}</span>
							<!-- PLACEHOLDER -->
						</button>
					</template>

					<template #dropdown>
						<!-- OPTIONS -->
						<div
							v-show="!required || allowNull"
							class="option"
							:class="{ focus: focusIndex == null }"
							ref="nullOption"
							@mouseover="focusOption(null)"
							@click="emit('update:modelValue', null)"
						>
							<span class="optionText nullOption textOverflowEllipsis">{{ placeholder }}</span>
						</div>

						<template v-if="options.length">
							<div
								v-for="(option, index) in options"
								:key="option[idKey]"
								class="option"
								:class="{ focus: focusIndex == index, disabled: option?.[disabledKey] }"
								@mouseover="focusOption(index)"
								@click="(event) => handleClick(option, event)"
							>
								<img v-if="imageKey && option[imageKey]" :src="option[imageKey]" class="optionImage" />
								<span
									class="optionText textOverflowEllipsis"
									:class="{
										active: option[idKey] === modelValue?.[idKey],
									}"
									>{{ option[nameKey] }}</span
								>
							</div>
							<!-- OPTIONS -->
						</template>
					</template>
				</CustomDropdown>
			</div>
		</label>

		<div class="inputError" v-if="errors.length > 0">
			{{ errors[0]?.$message }}
		</div>
	</div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from "vue";
import CustomDropdown from "@/components/dynamic/CustomDropdown.vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
	modelValue: {
		type: [Object, String],
		default: null,
	},
	options: {
		type: Array,
		default: () => [],
	},
	// For each object in options, where is the id, name, image etc...
	idKey: {
		type: String,
		default: "id",
	},
	nameKey: {
		type: String,
		default: "name",
	},
	imageKey: {
		type: String,
		default: null,
	},
	disabledKey: {
		type: String,
		default: "disabled",
	},
	label: {
		type: String,
		default: null,
	},
	allowNull: {
		type: Boolean,
		default: false,
	},
	required: {
		type: Boolean,
		default: false,
	},
	placeholder: {
		type: String,
		default: null,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	errors: {
		type: Array,
		default: () => [],
	},
});

// ================= Mouse Control Start =================

const handleClick = (option, event) => {
	if (!option?.[props.disabledKey]) emit("update:modelValue", option);
	else dropdown.value.toggleDropdown("close");
};

// ================= Mouse Control End =================

// ================= Keyboard Control Start =================

const dropdown = ref(null);
const nullOption = ref(null);

const focusIndex = ref(null);

const focusOption = (index) => {
	focusIndex.value = index;
};

watch(
	() => props.options,
	() => (props.required ? (focusIndex.value = 0) : (focusIndex.value = null))
);

const moveFocus = (direction) => {
	if (!dropdown.value.isOpen) return;

	if (direction == -1) {
		if (props.required && focusIndex.value == 0) return;
		if (!props.required && focusIndex.value == null) return;
		focusIndex.value += direction;
	}
	if (direction == 1) {
		if (focusIndex.value >= props.options.length - 1) return;
		focusIndex.value += direction;
	}

	scrollIntoFocusedOption();
};

const scrollIntoFocusedOption = () => {
	nullOption.value.parentElement.children?.[focusIndex.value == null ? 0 : focusIndex.value + 1].scrollIntoView({
		inline: "nearest",
		block: "nearest",
		behavior: "smooth",
	});
};

const handleEnter = () => {
	if (!dropdown.value.isOpen) dropdown.value.toggleDropdown("open");
	else {
		if (!props.options?.[focusIndex.value]?.[props.disabledKey]) {
			emit("update:modelValue", props.options?.[focusIndex.value]);
			dropdown.value.toggleDropdown("close");
		}
	}
};

const closeDropdown = () => {
	dropdown.value.toggleDropdown("close");
};

const handleKeypress = (e) => {
	// Filter key presses from 0-9 or a-z (lookup ASCII or JS keyCode)
	if (!((e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 48 && e.keyCode <= 57))) return;

	let key = String.fromCharCode(e.keyCode).toLowerCase();

	// we want to start searching the next matching option, starting from the currently focused option
	for (let i = 0; i < props.options.length; i++) {
		// index of currently focused option (use modulo to cycle back)
		const displacedIndex = [i + focusIndex.value] % props.options.length;

		// skip the current option
		if (i == 0) continue;

		// stop searching when the next matching option is found
		if (props.options[displacedIndex][props.nameKey]?.[0]?.toLowerCase() == key) {
			focusIndex.value = displacedIndex;
			scrollIntoFocusedOption();
			return;
		}
	}
};

// ================= Keyboard Control End =================
</script>

<style lang="sass" scoped>
button.inputSelect
  text-align: start
  font-weight: var(--font-regular)
  background: var(--input-background)
  color: var(--text-regular)
  border-radius: 4px
  background-image: url('@/assets/icons/chevron-down-solid.svg')
  background-repeat: no-repeat
  background-size: 0.6rem
  background-position: right 1rem center
  cursor: default
.placeholder
  color: var(--text-regular)
  background: var(--transparent)
  vertical-align: initial

.select, .option
  height: 100%
  display: flex
  align-items: center
  gap: 0.5rem
.option
  height: 2.5rem
  padding: 0 1rem
  color: var(--text-regular)
  &:hover
    background: var(--dropdown-background-hover)
  &.focus
    background: var(--dropdown-background-hover)
  &.disabled
    opacity: 0.25
.nullOption
  opacity: 0.25
.optionText
  padding-right: 1rem
  &.active
    color: var(--text-accent)
.optionImage
  height: 2rem
  width: 3rem
  object-fit: contain

:deep(.dropdownContainer .dropdownPanel)
  max-height: 50vh
</style>
