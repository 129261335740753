<template>
	<div class="floatingMiniGamesContainer" v-if="!isUserHideMiniGames && IsShowMiniGames">
		<div class="floatingMiniGamesContent">
			<div class="closeBtn" @click="isUserHideMiniGames = !isUserHideMiniGames">&times;</div>
			<div v-b-modal="'minigames-modal'" class="unifiedLink cursorPointer">
				<div class="imageContainer">
					<div class="notificationBadge" v-if="miniGamesTicketBalance > 0">
						{{ miniGamesTicketBalance }}
					</div>
					<base-image :isGameImg="true" :imgSrc="miniGamesEntranceImg"></base-image>
				</div>
			</div>
		</div>
	</div>

	<b-modal
		id="minigames-modal"
		size="lg"
		class="defaultModal"
		centered
		:title="t('category.minigames')"
		:hide-footer="!isMobile"
	>
		<!-- ================= Available Ticket ================= -->
		<div class="availableTicket" v-if="isLogin">
			<span class="text">{{ t("common.ticketsAvailable") }}: </span>
			<span class="amount">{{ miniGamesTicketBalance }}</span>
			<font-awesome-icon :icon="['fas', 'ticket']" class="miniGameTicketIcon" />
		</div>

		<div class="miniGamesContent">
			<div
				v-for="minigameItem in getMiniGame"
				:key="minigameItem"
				class="minigameItem"
				:class="{ disabled: minigameItem?.isMaintenance }"
				@click="launchMiniGame(minigameItem)"
			>
				<div class="minigameItemContent">
					<div class="minigameImg">
						<BaseImage
							v-if="minigameItem.image != null"
							:imgSrc="minigameItem.image"
							:isFullUrl="true"
							:alt="minigameItem?.name"
							:title="minigameItem.miniGameType?.name"
						></BaseImage>
						<BaseImage
							v-else
							:isGameImg="true"
							:isFullUrl="false"
							:imgSrc="'MiniGames/' + minigameItem.miniGameType?.name.replace(' ', '') + '/entrance.gif'"
							:alt="minigameItem?.name"
							:title="minigameItem.miniGameType?.name"
						></BaseImage>
						<div v-if="minigameItem?.isMaintenance" class="gameImgOverlay">
							<font-awesome-icon class="overlayIcon maintenance" :icon="['fas', 'gear']" />
							<div class="overlayText">
								{{ t("common.underMaintenance") }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<template #footer>
			<button type="button" class="mainButton" data-bs-dismiss="modal">
				{{ t("common.close") }}
			</button>
		</template>
	</b-modal>
</template>
<script setup>
import { ref, computed, defineProps, watch, nextTick, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { routeData } from "@/constant";
import { isLogin } from "@/composables/useAuth";
import { useIsMobile } from "@/composables/useMobile";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";
import BaseImage from "@/components/dynamic/BaseImage.vue";
import { toastOptionError } from "@/constant";
import { useToast } from "vue-toastification";
import { useLoading } from "vue-loading-overlay";

const store = useStore();
const router = useRouter();
const route = useRoute();
const isMobile = useIsMobile();
const { t, locale } = useI18n();
const toast = useToast();
const $loading = useLoading();

const newWin = ref(null);

const miniGamesEntranceImg = "MiniGames/entrance.gif";

// ================= MiniGames Start =================

const isUserHideMiniGames = ref(false);

const IsShowMiniGames = computed(() => (store.getters["miniGames/getMiniGamesList"].length > 0 ? true : false));

const getMiniGame = computed(() => store.getters["miniGames/getMiniGamesList"]);

const miniGamesUser = computed(() => store.getters["miniGames/getMiniGameUser"]);
const miniGamesTicketBalance = computed(() => miniGamesUser.value?.ticketBalance || 0);

const launchMiniGame = async (minigameItem) => {
	if (minigameItem?.isMaintenance) return;

	const loader = $loading.show();
	if (newWin.value && !newWin.value?.closed) newWin.value.close();
	newWin.value = window.open();
	if (!newWin.value || newWin.value.closed || newWin.value.closed == "undefined") {
		toast.error(t("toast.popUpBlocked"), toastOptionError);
	}

	await store
		.dispatch("miniGames/fetchMiniGamesUrl", { lang: locale.value, minigameId: minigameItem.id })
		.then((minigameUrl) => {
			if (minigameUrl) {
				newWin.value.location = minigameUrl;
				// window.location.href = minigameUrl;
			} else {
				toast.error(t("toast.urlNotFound"), toastOptionError);
				newWin.value?.close();
			}
		})
		.catch(() => {
			toast.error(t("toast.launchFailed"), toastOptionError);
			newWin.value?.close();
		})
		.finally(() => {
			loader.hide();
		});
};
// ================= MiniGames End =================
</script>
<style lang="sass" scoped>
.floatingMiniGamesContainer
	position: fixed
	bottom: 1rem
	left: 1rem
	z-index: 40
	.floatingMiniGamesContent
		width: 9rem
		position: relative
	.closeBtn
		font-size: 1.2rem
		position: absolute
		right: -.5rem
		top: -.5rem
		cursor: pointer
		padding: 1rem
		color: var(--text-regular)
		border-radius: 50%
		display: flex
		justify-content: center
		align-items: center
		width: 1rem
		height: 1rem
		z-index: 1
		&:hover
			transform: scale(1.5)
			transition: transform 0.1s linear
.imageContainer
	position: relative
	cursor: pointer
	transition: transform 0.15s
	&:hover
		transform: scale(1.1)
.notificationBadge
	position: absolute
	top: 1.2rem
	right: 1.2rem
	line-height: 1em
	font-size: 1em
	font-weight: var(--font-bold)
	text-align: center
	color: var(--text-inverted)
	background: var(--danger)
	min-width: 1.5em
	border-radius: 999rem
	// outline: 1.5px solid var(--background-primary)
	padding: 0.25em 0.25em
	@media (max-width: 767px)
		top: 0.8rem
		right: 0.8rem
.availableTicket
	text-align: center
	font-weight: var(--font-medium)
	.miniGameTicketIcon
		margin-left: 0.5rem
.miniGamesContent
	display: grid
	flex-wrap: wrap
	gap: 1rem
	grid-template-columns: repeat(5, 1fr)
	margin-block: 1rem
.minigameItem
	cursor: pointer
	&.disabled
		cursor: default
		.minigameImg img
			filter: brightness(0.5)

		.gameImgOverlay
			position: absolute
			height: 100%
			width: 100%
			top: 0
			left: 0
			display: flex
			flex-direction: column
			align-items: center
			justify-content: center
			gap: 0.5rem
			padding: 0.5rem
			color: var(--text-inverted)
		.overlayIcon
			font-size: 1.5em
			&.comingSoon
				animation-duration: 2s
			&.maintenance
				animation-duration: 5s
		.overlayText
			font-size: 0.7em
			font-weight: var(--font-black)
			font-style: italic
			text-transform: uppercase
.minigameItemContent
	height: 100%
	display: flex
	justify-content: center
	align-items: center
	text-align: center
	flex-direction: column
.gameName
	font-size: 0.8rem
	white-space: nowrap
	text-align: center
	margin-top: 0.5rem

@media (max-width: 767px)
	.floatingMiniGamesContainer
		bottom: calc(var(--mobile-dock-height) + 0.5rem)
		left: 0.5rem
		.floatingMiniGamesContent
			width: 6rem
	.miniGamesContent
		grid-template-columns: repeat(3, 1fr)
		gap: 1rem
</style>
