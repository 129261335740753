import { BetSummaryResponse, BetHistoryResponse } from "@/types/Report/Response/ReportResponse";

export interface ReportStateInterface {
	betSummary: BetSummaryResponse[];
	betHistory: BetHistoryResponse[];
}

function state(): ReportStateInterface {
	return {
		betSummary: [],
		betHistory: [],
	};
}

export default state;
